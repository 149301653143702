import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { CustomeAmountPipe } from 'src/app/shared/pipes/amount.pipe';
import { SelectedHeaderService } from 'src/app/shared/services/selected-header.service';
import { WBANotifierService } from '../../services/wba-notifier.service';
import { WhiteBoardActivityService } from '../../services/white-board-activity.service';
import { CustomNotifireService } from 'src/app/shared/services/view-prospect-notifire.service';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { GridComponent, PageChangeEvent, SelectableMode, SelectableSettings } from '@progress/kendo-angular-grid';
import { Subject, forkJoin } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { environment } from 'src/environments/environment';
import { prospectCreateList } from 'src/app/features/prospect/interfaces/criteria.model';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { AddprospectService } from 'src/app/features/prospect/services/addprospect.service';
import { SignalRNotifierService } from 'src/app/features/admin/services/Notifier Services/signalR-notifier.service';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { Title } from '@angular/platform-browser';
import { UserManagementService } from 'src/app/features/user-management/services/user-management.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridColumnSelectionModalComponent } from 'src/app/features/list-management/components/grid-column-selection-modal/grid-column-selection-modal.component';
import { ManageListService } from 'src/app/features/list-management/services/ManageList.service';
import { AcmeProductService } from 'src/app/features/acme/service/acme-product.service';
import { CloneBorComponent } from '../add-whiteboard/clone-bor/clone-bor.component';
import { DatacacheService } from '../../services/datacache.service';
import { MasterDatacacheService } from '../../services/masterdatacache.service';

@Component({
  selector: 'app-whiteboard-list',
  templateUrl: './whiteboard-list.component.html',
  styleUrls: ['./whiteboard-list.component.css'],
})
export class WhiteboardListComponent implements OnInit {
  @ViewChild('closebutton') closebutton !: ElementRef<any>;
  @ViewChild('template1', { static: true })
  public template1 !: TemplateRef<any>;
  @ViewChild('template2', { static: true })
  public template2 !: TemplateRef<any>;
  @ViewChild('template3', { static: true })
  public template3 !: TemplateRef<any>;
  @ViewChild('templateForPolicy', { static: true })
  public templateForPolicy !: TemplateRef<any>;
  @ViewChild('templateForNumber', { static: true })
  public templateForNumber!: TemplateRef<any>;
  @ViewChild('templateForNumberEXP', { static: true })
  public templateForNumberEXP!: TemplateRef<any>;
  @ViewChild('templateForNumberWithoutComma', { static: true })
  public templateForNumberWithoutComma!: TemplateRef<any>;
  @ViewChild('templateForClientStatus', { static: true })
  public templateForClientStatus!: TemplateRef<any>;
  @ViewChild('templateForRecallStatus', { static: true })
  public templateForRecallStatus!: TemplateRef<any>;
  @ViewChild('templateForWbaStatus', { static: true })
  public templateForWbaStatus!: TemplateRef<any>;
  @ViewChild('templateForWbaBussinessStatus', { static: true })
  public templateForWbaBussinessStatus!: TemplateRef<any>;
  @ViewChild('templateForPrimaryIndustry', { static: true })
  public templateForPrimaryIndustry!: TemplateRef<any>;
  @ViewChild('templateForSecondaryIndustry', { static: true })
  public templateForSecondaryIndustry!: TemplateRef<any>;
  @ViewChild('templateForProgress', { static: true })
  public templateForProgress !: TemplateRef<any>;
  @ViewChild('templateForResult', { static: true })
  public templateForResult !: TemplateRef<any>;
  @ViewChild('templateprofitCenter', { static: true })
  public templateprofitCenter !: TemplateRef<any>;
  @ViewChild('grid', { static: true }) public grid!: GridComponent;
  @ViewChild('templatewbaOwner', { static: true })
  public templatewbaOwner !: TemplateRef<any>;
  @ViewChild('templateForUserName', { static: true })
  public templateForUserName !: TemplateRef<any>;
  public whiteBoardActivityList: any = [];
  public _confirmBox: ConfirmBox;
  public _warningBox: ConfirmBox;
  public _userMessages: UserMessages;
  public _exportConfirmBox: ConfirmBox;
  public id: number = 0;
  public _navigationPath: NavigationPath;
  public searchText: string = '';
  sortingData = JSON.parse(sessionStorage.getItem('wbaFilterStorage')!);
  pageSize = this.sortingData != null ? this.sortingData.pageSize : 50;
  public buttonCount = 5;
  public sizes = [10, 25, 50];
  skip = this.sortingData != null ? (this.sortingData.pageNumber - 1) * this.sortingData.pageSize : 0;
  totalCount = this.sortingData != null ? this.sortingData.totalCount : 0;
  pageNo: number = this.sortingData != null ? this.sortingData.pageNumber : 1;
  selectedListId = this.sortingData != null ? this.sortingData.listId : 0;
  totalRecords = 0;
  public finalWBAList: any = [];
  public defaultFilter: CompositeFilterDescriptor = { filters: [], logic: 'and' };
  public filterValue: CompositeFilterDescriptor = { ...this.defaultFilter };
  public gridFilters: CompositeFilterDescriptor = { ...this.defaultFilter };
  public FormattedgridFilter: CompositeFilterDescriptor = { ...this.defaultFilter };
  public FormattedFilterValues: any;
  public masterWBAList: any[] = [];
  public gridData: unknown[] = this.masterWBAList;
  public isFilter: boolean = false;
  public isFilter1: boolean = false;
  private $searchingText = new Subject<void>();
  public dateFilters: any[] = [];
  listTitle: string = 'All Whiteboards';
  selectedDefaultList: any = '';
  queryDetail: any;
  defaultList: any;
  columnsOfList: any[] = [];
  prospectCreateList: prospectCreateList[] = [];
  selectedId: string = '0';
  hideShowFilter: boolean = false;
  dblClickSelectedWba: any
  businessLine: any;
  selectedStateFilterValue: any;
  selectedLobFilterValue: any;
  filterValueArraycontactState: any[] = [];
  currentDate: any;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  public filters: FilterExpression[] = [
    {
      "field": "companyName",
      "title": "Company Name",
      "editor": "string"
    }
  ]
  columnWidthMaster: any = {}

  ColumnOrderMaster: any = []
  columnsOfListMasterData: any = [];
  isBorRenuewshow: boolean = false;
  isPageChanged: boolean = false;
  filtersList: any = [];

  setWbaId(ev: any) {
    console.log(ev)
  }

  public sort: SortDescriptor[] = [
    {
      field: this.sortingData != null ? this.sortingData.sortCol : 'effectiveDate',
      dir: this.sortingData != null ? this.sortingData.sortDir : 'desc',
    }
  ];
  filterData: any;
  unAthorizesd: any = '';
  showError: boolean = false;
  wbaView: any = [];
  currentSelectedField: any;
  fieldValue: any;
  fieldValueforContact: any
  selectedItem: any;
  filterForLob: any[] = [];
  selectedItemForLOB: any;
  selectedItemForContactState: any
  filterValueArrayState: any[] = [];
  filterValueArrayLob: any[] = [];
  filterValueForState: any;
  filterValueForLob: any;
  statesList: any;
  selectedContactStateFilterValue: any;
  filterValueForContact: any;
  gridFilterForState: any;
  gridFilterArrayState: any[] = [];
  gridFilterArrayAcordStatus: any[] = [];
  gridFilterArrayResult: any[] = [];
  gridFilterArrayLob: any[] = [];
  resultList: any;
  userDetails: any;
  totalCountMaster: any;
  progress: any;
  filterForAcordStatus: any;
  filterForLossRunStatus: any;
  filterForApplicationStatus: any;
  gridFilterForAcordStatus: any;
  gridFilterForResult: any;
  gridFilterForLossRun: any;
  gridFilterArrayLossRun: any[] = [];
  gridFilterArrayApplication: any[] = [];
  gridFilterForApplication: any;
  gridFilterForLob: any;
  onloadApi: boolean = false;
  filterValueCopy: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  masterColumnList: any;
  masterColumnOrder: any;
  private lastClickTime: number = 0;
  private currentClickRow: any = null;
  controlId: any = this.newGuid();
  gridFilterForProgress: any;
  gridFilterForwbaOwnerList: any
  gridFilterArrayProgress: any[] = [];
  gridOwnerWbaListArray: any[] = [];
  gridmarketingManagerListArray: any[] = [];
  gridAcctManagerListArray: any[] = [];
  resultListForGrid: any;
  isValidUser: boolean = false;
  clientStatus: any;
  recallForStatusList: any;
  wbaForStatusList: any;
  businessStatusList: any;
  gridFilterForBussinessStatus: any;
  gridFilterArrayBussinessStatus: any[] = [];
  gridFilterArrayPolicyType: any[] = [];
  gridFilterForPolicyType: any;
  prospectIndustryList: any;
  policyTypeList: any;
  UserIdLogged: any;
  pinUnpinList: boolean = false;
  pinnedListId: any;
  filterBlock: boolean = true;
  EpicStatusFilterForResult: any;
  callFrom: any;
  reasonForReopenWBA: string = "";
  invalidReasonForReopenWBA: boolean = false;
  profitCenterData: any;
  isValidUserwbaOpen: boolean = false;
  isExportVisible: boolean = false;
  public mySelection: number[] = [];
  unHideFlagForCheckBox: boolean = true;
  checkboxCheckedData: any = [];
  isGridFilter: boolean = true;
  //800, 42211
  allCols: any = [];
  selectedWbaCount: boolean = false;
  isInValidNumber: boolean = false;
  orderedCols: any;
  WBAOwneruserList: any;
  userList: any;
  _confirmBoxForClone: ConfirmBox;
  selectResetArr: any = {};
  public checkboxOnly = true;
  public mode: SelectableMode = "multiple";
  public drag = true;
  public selectableSettings!: SelectableSettings;
  removeFilterForhideColumn: boolean = false;
  wbaFilterJsonPayload: any;
  resetList: any;
  errorList: any[] = [];
  reordered: boolean = false;
  ColumnReorderable: any = true;
  clickTimeout: any;
  public setSelectableSettings(): void {
    if (this.checkboxOnly || this.mode === "single") {
      this.drag = false;
    }

    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
  }
  selectedDefaultListName: any = '    '
  isAllWbaSelected: boolean = false;
  filterDataEmpty = "";

  constructor(
    private _router: Router,
    private _whiteBoardActivityService: WhiteBoardActivityService,
    private _selectedHeaderService: SelectedHeaderService,
    private router: Router,
    private _customNotifireService: CustomNotifireService,
    public _amountPipe: CustomeAmountPipe,
    private _notifierService: WBANotifierService,
    private datepipe: DatePipe,
    private _ProspectListService: ProspectListService,
    private _preventBack: PreventBackButtonService,
    public profileInfoService: ProfileInfoService,
    private _addWBAService: WhiteBoardActivityService,
    private _AddprospectService: AddprospectService,
    private datePipe: DatePipe,
    private signalRNotifire: SignalRNotifierService,
    private _loaderService: LoaderService,
    private ref: ChangeDetectorRef,
    private titleService: Title,
    private userManagementService: UserManagementService,
    private modalService: NgbModal,
    private _manageListService: ManageListService,
    private _dataCacheService: DatacacheService,
    private _masterDataCacheService: MasterDatacacheService,
    private cdr: ChangeDetectorRef
  ) {
    this._confirmBox = new ConfirmBox();
    this._exportConfirmBox = new ConfirmBox();
    this._confirmBoxForClone = new ConfirmBox();
    this._warningBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
    this.setSelectableSettings();

    // calling api after 1 sec when entring test in search text
    this.$searchingText.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.loadData(this.selectedListId);
    });

  }

  @HostListener('document:keypress', ['$event'])
  startSearch(event: KeyboardEvent) {
    if (event.code === "Enter") {
      this.onSearch()
    }
  }

  ngOnInit(): void {
    // Load asynchronously  check authorization
    //this.checkAuthorization();

    // Load user information asynchronously
    this.userInformation();

    // Initialize master data asynchronously
    this.initializeMasterData();

    // Set the title
    this.titleService.setTitle('WhiteBoard Activities');

    // Initialize filter options
    this.initFilterOptions();

    // Set the current date
    this.setCurrentDate();

    // Prevent back button
    this.preventBackButton();

    // setTimeout(() => {
    //   this.finalWBAList.data = [{}, {}, {},{}, {}, {},{}, {}, {},{}, {}, {}];
    // this.finalWBAList = {
    // data: Array.from({length: 10}, (_, i) => {test: 'test'}),
    // // total: 0
    // };
    this.cdr.detectChanges()
    // }, 0)

    // Handle page initialization based on session storage
    this.handlePageInitialization();


    // Set active component in custom notifier service
    this._customNotifireService.setActiveComponent('Whiteboard');;

    // Subscribe to profile info changes
    this.subscribeToProfileInfoChanges();
  }

  closeError() {
    this.showError = false;
    this.unAthorizesd = '';
  }

  //to get Business Line list
  getBusinessLineList() {
    this._addWBAService.getBusinessLineList().subscribe(resp => {
      this.businessLine = resp.businessLineList;
    })
  }
  getBusinessStatusList(): void {
    this._addWBAService.getBusinessStatusList().subscribe((response: any) => {
      this.businessStatusList = response.businessStatusList
    });
  }

  //to get Result list
  getResultList() {
    this._addWBAService.getResultList().subscribe(resp => {
      this.resultList = resp.epicStatusList;
    })
  }

  //to get Progress list
  getProgressList() {
    this._addWBAService.getprogressList().subscribe(resp => {
      this.progress = resp.progressList;
    })
  }

  //to get policy Type list for speciality
  getPolicyTypeList() {
    this._addWBAService.getPolicyTypeList(5).subscribe(resp => {
      this.policyTypeList = resp.businessLineList

    })
  }
  updateUserForProspect() {
    this.pinUnpinList = this.selectedListId == 0 ? false : true;
    //this.pinnedListId = this.selectedListId;
    let saveData: any;
    if (this.pinnedListId == this.selectedListId) {
      this.pinUnpinList = false;
      this.pinnedListId = 0;
    } else {
      this.pinUnpinList = true;
      this.pinnedListId = this.selectedListId;
    }

    this.userDetails = JSON.parse(localStorage.getItem('userDetails')!);
    this.userDetails.user.defaultWBAListId = this.pinnedListId;
    localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
    saveData = {
      "listId": this.pinnedListId == 0 ? 0 : this.selectedListId,
      "prospectId": 0,
      "contactId": 0,
      "wbaId": 0,
      "entityType": "Wba",
      "userId": this.UserIdLogged
    }
    this._ProspectListService.updateUserForProspect(saveData).subscribe();
  }

  public onFilterChange(value: any): void {
  }

  public editorValueChange(
    value: any,
    currentItem: FilterDescriptor,
    filterValue: CompositeFilterDescriptor
  ): void {
    currentItem.value = value;
    if (currentItem.field == 'State') {
      this.selectedItem = value
      this.currentSelectedField = 'State';
    } else if (currentItem.field == 'MasterBusinessLine') {
      this.selectedItemForLOB = value
      this.currentSelectedField = 'MasterBusinessLine';
    }
    else if (currentItem.field == '"Office1State"') {
      this.selectedItemForContactState = value
      this.currentSelectedField = '"Office1State"';
    }
  }

  public getFilterJSON(): void {
    this._whiteBoardActivityService.getFilterJSON().subscribe((res: any) => {
      this.filters = res.masterFilterOptionList;
      let filterFoeState = res.masterFilterOptionList[26].filterOptions;
      let filterforContactState = res.masterFilterOptionList[7].filterOptions;
      this.clientStatus = ['Not a client', 'Client', 'Lost Client'];
      this.recallForStatusList = ['Open', 'Completed', 'Expired'];
      this.wbaForStatusList = ['Open', 'Won', 'Lost'];
      this.profitCenterData = ['Lamb', 'CREIS', 'TruePartners', 'TruePartners - Wheels']
      let stateArray: any[] = []
      this.filters.forEach((element: any) => {
        if (element?.field == 'State') {
          element.operators = ["eq"];
        }
        else if (
          element.field == 'ProspectInformation.NoOfEmployees' ||
          element.field == 'ProspectInformation.Revenue' ||
          element.field == 'ProspectInformation.PackagePremium' ||
          element.field == 'ProspectInformation.WorkersCompensationPremium' ||
          element.field == 'TargetPremium' ||
          element.field == 'WBAOutComeDetail.BoundRevenue' ||
          element.field == 'Prospect.TotalRevenue' ||
          element.field == 'Prospect.DispositionCount'
        ) {
          element.editorTemplate = this.templateForNumber;
        }
        else if (
          element.field == 'ProspectInformation.EXPMOD'
        ) {
          element.editorTemplate = this.templateForNumberEXP;
        }
        else if (
          element.field == 'WBA.WBAId' || element.field == 'WBA.ProspectId'
        ) {
          element.editorTemplate = this.templateForNumberWithoutComma;
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'MasterBusinessLine') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'Office1State') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'MasterPolicyType.line') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'prospectstatus.Status') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'WBARecall.Status') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'WBAStatusId') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'MasterBusinessStatus') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'pmv_view.TagName' || element.field == 'Tag.Name') {
          element.operators = ["contains", "eq", "neq", "doesnotcontain", "isnull"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'industryclassandcodeinformation.Class.NTEE') {
          element.operators = ["contains", "eq", "neq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'industryclassandcodeinformation.Class.PCS') {
          element.operators = ["contains", "eq", "neq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'industryclassandcodeinformation.Class.SIC') {
          element.operators = ["contains", "eq", "neq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element.field == 'industryclassandcodeinformation.Class.NAICS') {
          element.operators = ["contains", "eq", "neq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'ProspectIndustry.IndustryName') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'PI.IndustryName') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'ProgressName') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'EpicStatusName') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'ProfitCenter') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'WBA.OwnerUserName') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'Prospect.CreatedBy') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'WBA.CreatedBy') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'WBA.MarketingManagerId') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'Prospect.LastContactedBy') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'Prospect.ModifiedBy') {
          element.operators = ["eq"];
        }
      });
      this.filters.forEach((element: any) => {
        if (element?.field == 'wba.OriginalProducerName') {
          element.operators = ["eq"];
        }
      });
      this.fieldValue = stateArray;
      let stateForContactArray: any[] = []
      filterforContactState.forEach((element: any) => {
        stateForContactArray.push(element.stateCode)
      });
      this.fieldValueforContact = stateForContactArray;
      let getStateIndex = this.filters.findIndex((x: any) => x.field == 'State')!
      let getLobIndex = this.filters.findIndex((x: any) => x.field == 'MasterBusinessLine')!
      this.filters[getStateIndex].editorTemplate = this.template1;
      this.filters[getLobIndex].editorTemplate = this.template2;
      let getContactStateIndex = this.filters.findIndex((x: any) => x.field == 'Office1State')!
      this.filters[getContactStateIndex].editorTemplate = this.template3;
      let getspecialPolicyIndex = this.filters.findIndex((x: any) => x.field == 'MasterPolicyType.line')!
      this.filters[getspecialPolicyIndex].editorTemplate = this.templateForPolicy;
      let getspecialClientStatus = this.filters.findIndex((x: any) => x.field == 'prospectstatus.Status')!
      this.filters[getspecialClientStatus].editorTemplate = this.templateForClientStatus;
      let getRecallStatus = this.filters.findIndex((x: any) => x.field == 'WBARecall.Status')!
      this.filters[getRecallStatus].editorTemplate = this.templateForRecallStatus;
      let getWbaStatus = this.filters.findIndex((x: any) => x.field == 'WBAStatusId')!
      this.filters[getWbaStatus].editorTemplate = this.templateForWbaStatus;
      let getWbaBussinessStatus = this.filters.findIndex((x: any) => x.field == 'MasterBusinessStatus')!
      this.filters[getWbaBussinessStatus].editorTemplate = this.templateForWbaBussinessStatus;
      let getPrimaryIndustry = this.filters.findIndex((x: any) => x.field == 'ProspectIndustry.IndustryName')!
      this.filters[getPrimaryIndustry].editorTemplate = this.templateForPrimaryIndustry;
      let getSecondaryIndustry = this.filters.findIndex((x: any) => x.field == 'PI.IndustryName')!
      this.filters[getSecondaryIndustry].editorTemplate = this.templateForSecondaryIndustry;
      let getspecialProgressIndex = this.filters.findIndex((x: any) => x.field == 'ProgressName')!
      this.filters[getspecialProgressIndex].editorTemplate = this.templateForProgress;
      let getspecialResultIndex = this.filters.findIndex((x: any) => x.field == 'EpicStatusName')!
      this.filters[getspecialResultIndex].editorTemplate = this.templateForResult;
      let getprofitCenter = this.filters.findIndex((x: any) => x.field == "ProfitCenter")!
      this.filters[getprofitCenter].editorTemplate = this.templateprofitCenter;
      // let gettemplatewbaOwner = this.filters.findIndex((x: any) => x.field == "OwnerUserName")!
      // this.filters[gettemplatewbaOwner].editorTemplate = this.templatewbaOwner;
      let getownerUserName = this.filters.findIndex((x: any) => x.field == "WBA.OwnerUserName")!
      this.filters[getownerUserName].editorTemplate = this.templateForUserName;
      let getProspectCreatedBy = this.filters.findIndex((x: any) => x.field == "Prospect.CreatedBy")!
      this.filters[getProspectCreatedBy].editorTemplate = this.templateForUserName;
      let getWBACreatedBy = this.filters.findIndex((x: any) => x.field == "WBA.CreatedBy")!
      this.filters[getWBACreatedBy].editorTemplate = this.templateForUserName;
      let getWBAMarketingManagerId = this.filters.findIndex((x: any) => x.field == "WBA.MarketingManagerId")!
      this.filters[getWBAMarketingManagerId].editorTemplate = this.templateForUserName;
      let getProspectLastContactedBy = this.filters.findIndex((x: any) => x.field == "Prospect.LastContactedBy")!
      this.filters[getProspectLastContactedBy].editorTemplate = this.templateForUserName;
      let getProspectModifiedBy = this.filters.findIndex((x: any) => x.field == "Prospect.ModifiedBy")!
      this.filters[getProspectModifiedBy].editorTemplate = this.templateForUserName;
      let getwbaOriginalProducerName = this.filters.findIndex((x: any) => x.field == "wba.OriginalProducerName")!
      this.filters[getwbaOriginalProducerName].editorTemplate = this.templateForUserName;
      this.dateFilters = this.filters.filter((obj: any) => obj.editor === 'date');
    })
    this.setDataUsingFilterStorage();
    this.selectedDefaultList = this.selectedListId.toString();
  }



  getProspectStates(): void {
    this._AddprospectService.getProspectStates().subscribe((response: any) => {
      this.statesList = response.statesList
    });
  }


  onCreateListChange(event: any, callfrom: any) {
    // Show loader
    this._loaderService.show();

    // Get list name if call is not from page load
    if (callfrom != 'pageload') this.selectedDefaultListName = this.getListName(event.value);

    // Set sessionStorage item
    sessionStorage.setItem('selectWBAID', event.value);

    // Actions for onchange call
    if (callfrom == 'onchange') {

      // Clear selections and session storage items
      this.mySelection = [];
      this.checkboxCheckedData = [];
      sessionStorage.removeItem('checkboxselectedWithoutHide');
      sessionStorage.removeItem('wbaFilterStorage');
      sessionStorage.removeItem('wbaFilterStorageforCheckedRow');
      sessionStorage.removeItem('mySelection');
      // Clear multiselect values
      this.clearValueMultiselect();
      // Set flag
      this.unHideFlagForCheckBox = true;

      // Check if list is pinned
      if (this.pinnedListId == parseInt(event.value)) {
        this.pinUnpinList = true;
      } else {
        this.pinUnpinList = false;
      }

      // Reset pagination and filter values
      this.pageNo = 1;
      this.skip = 0;
      this.pageSize = 50;
      this.searchText = "";
      this.totalCount = 0;
      this.filterValue = { logic: 'and', filters: [] };
      this.gridFilters = { logic: 'and', filters: [] };
      this.filterValueCopy = { logic: 'and', filters: [] };
      this.isFilter1 = false;
    }

    // Set selected list id
    this.selectedListId = event.value;

    // Reset filter value
    this.filterValue = { logic: 'and', filters: [] };

    // Check if selectedListId is not 0
    if (parseInt(this.selectedListId) > 0) {
      this.hideShowFilter = false;
      // Call method to get query by id
      this.getQueryById(event.value);
    } else {
      // For list id 0
      this.hideShowFilter = true;
      this.selectedDefaultList = this.selectedListId.toString();
      this.getDynamicColumnList();
    }

    // Reload component if reordered
    if (this.reordered) {
      this.reloadComponent(true);
    }

    // Set selected default list
    this.selectedDefaultList = this.selectedListId.toString();
  }


  //clear mutlselect dropdown filter value
  public clearValueMultiselect() {
    this.gridFilterArrayLob = [];
    this.gridFilterArrayPolicyType = [];
    this.gridFilterArrayBussinessStatus = [];
    this.gridFilterArrayState = [];
    this.gridFilterArrayProgress = [];
    this.gridFilterArrayResult = [];
    this.gridFilterArrayAcordStatus = [];
    this.gridFilterArrayLossRun = [];
    this.gridFilterArrayApplication = [];
    this.gridOwnerWbaListArray = [];
    this.gridmarketingManagerListArray = [];
    this.gridAcctManagerListArray = [];
  }

  //get list details By id
  getQueryById(id: any) {
    this._ProspectListService.getWBAById(id).subscribe({
      next: (resp: any) => {
        if (resp) {
          if (resp.listFilter != null) {
            this.queryDetail = resp.listFilter;
            this.listTitle = this.queryDetail.listName;
            this.filterValue = JSON.parse(this.queryDetail.filterJson);
            this.filterValueCopy = JSON.parse(this.queryDetail.filterJson);
          }
          this.selectedDefaultList = this.selectedListId ? this.selectedListId.toString() : '0';
          this.getDynamicColumnList();
        }
      },
      error: () => { },
    });
  }

  isGridColumnVisible(colName: string): boolean {

    if (this.columnsOfList.length > 0) {
      let columnInfo = this.columnsOfList.find(x => x.columnName.toLowerCase() === colName.toLowerCase() && x.isVisible);
      return columnInfo !== undefined ? !columnInfo.isVisible : true;
    }

    return false;

  }




  DateFormattingInAllGroups(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.DateFormattingInAllGroups(x)
        }
        else {
          let fieldType = this.filters.find((f: any) => f.field.toLowerCase() === x.field.toLowerCase())?.editor;
          if (fieldType) {
            if (fieldType?.toLowerCase() == 'date') {
              // var myDate = new Date(x.value).toISOString();
              x.value = this.datepipe.transform(x.value, 'yyyy-MM-dd');
            }
          }
        }
      })
    }
    return json;
  }

  convertStringIntoDate(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertStringIntoDate(x)
        }
        else {
          let fieldType = this.filters.find((f: any) => f.field.toLowerCase() === x.field.toLowerCase())?.editor;
          if (fieldType) {
            if (fieldType?.toLowerCase() == 'date') {
              // x.value = new Date(x.value)
              let formattedDate: any = this.datepipe.transform(x.value, 'MMMM d, y');
              x.value = new Date(formattedDate);
            }
          }
        }
      })
    }
    return json;
  }

  changeDetector() {
    this.ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 2000);
  }


  // Telrik functions start
  public applyFilter(value: CompositeFilterDescriptor, val: boolean): void {

    this.filterBlock = false;
    this.ref.detectChanges();
    this.filterValueCopy = JSON.parse(JSON.stringify(value));
    this.filterValue = value;
    this.DateFormattingInAllGroups(this.filterValueCopy)
    // this.FormattedFilterValues = { ...value };
    // this.FormattedFilterValues.filters = this.getFormattedJSON(value.filters);
    this.resetOptions();
    this.loadData(this.selectedListId, false, true);
    if (this.filterValueCopy.filters.length > 0) {
      this.isFilter1 = true;
      this.selectResetArr["filter"] = true;
    }
    else {
      this.isFilter1 = false;
    }
    // this.isFilter1 = !val;
  }

  // changing the format for the filters of the date type
  public getFormattedJSON(data: any) {
    data.map((obj: any) => {
      if (obj.field) {
        let index = this.dateFilters.findIndex((obj1: any) => obj1.field.toLocaleLowerCase() === obj.field.toLocaleLowerCase());

        if (index > -1) {
          let record: any = this.datepipe.transform(obj.value, 'yyyy-MM-dd');
          obj.value = record;
        }
      } else if (obj.filters) {
        obj.filters = this.getFormattedJSON(obj.filters)
      }
    });
    return data;
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.gridFilters = { ...filter };
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    this.selectResetArr["columnFilter"] = true;
    (document.getElementById('columnFilter') as HTMLInputElement).checked = true;
    this.FormattedgridFilter.filters = this.getFormattedJSON(filter.filters);
    this.resetOptions();
    this.loadData(this.selectedListId, false, true)
  }

  searchValue(inputValue: any) {
    this.searchText = inputValue
    this.filterData.searchText = this.searchText;
  }

  public onSearch(): void {
    //let data = JSON.parse(sessionStorage.getItem('wbaFilterStorage')!)
    let data = JSON.parse(this.wbaFilterJsonPayload ? this.wbaFilterJsonPayload : '{}');
    if (this.searchText === "" && data?.searchText === "") {
      return
    }
    else {
      this.resetOptions();
      this.loadData(this.selectedListId, false, true);
    }
  }

  resetOptions(): void {
    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;

  }


  executiveSummaryById(val: any, prospectId: any) {
    this._notifierService.setValue(val.id);
    this.getWhiteBoardDetails(val.id);
  }

  renewById(rowData: any) {
    const renewPolicy: any = {
      wbaId: rowData.id,
      wbaPolicyId: rowData.epicUniquePolicyId,
      wbaProspectId: rowData.prospectId
    }

    sessionStorage.setItem("renewPolicy", JSON.stringify(renewPolicy));
    this._notifierService.setValue(rowData.id);
    this.router.navigate([
      '/' + this._navigationPath.getWhiteboardsUrl() + '/' + this._navigationPath.getexRenewUrl()
    ], { queryParams: { wbaId: renewPolicy.wbaId, policyId: renewPolicy.wbaPolicyId, listId: this.selectedListId, prospectId: renewPolicy.wbaProspectId } });
  }


  navigate(id: any, prospectid: any, comp: any) {
    sessionStorage.setItem(environment.storageVariables.wbaId, id);
    sessionStorage.setItem('prospectId', prospectid);
    if (comp == this._navigationPath.getWhiteboardUrl()) {
      this._router.navigateByUrl(
        '/' + this._navigationPath.getWhiteboardUrl() + '/' + comp
      );
    } else {
      this._selectedHeaderService.setSelectedHeader(
        this._navigationPath.getProspectsUrl()
      );
      this._customNotifireService.setActiveComponent('Prospect');
      this._router.navigateByUrl(
        '/' + this._navigationPath.getProspectsUrl() + '/' + comp
      );
    }
  }

  confirmBoxCancel() {
    this.id = 0;
    this._confirmBox.isVisible = false;
  }

  //to open 'Delete WBA' Modal
  openDeleteModel(id: any) {
    this.callFrom = ''
    this._confirmBox.message = this._userMessages.deleteButtonWbaMessage;
    this._confirmBox.isVisible = true;
    this.id = id;
  }

  convertingToDateType() {
    this.whiteBoardActivityList.map((obj: any) => {
      if (obj.effectiveDate) {
        obj.effectiveDate = new Date(obj.effectiveDate);
      }
    })
  }

  deleteWhiteboardActivity() {
    this._whiteBoardActivityService
      .deleteWhiteboardActivityById(this.id).subscribe((resp) => {
        if (resp) {
          let setTotalCountZero = JSON.parse(sessionStorage.getItem('wbaFilterStorage')!)
          setTotalCountZero.totalCount = setTotalCountZero.totalCount - 1
          sessionStorage.setItem('wbaFilterStorage', JSON.stringify(setTotalCountZero));

          this.confirmBoxCancel();
          this.whiteBoardActivityList = this.whiteBoardActivityList.filter(
            (item: any) => item.id != this.id
          );
          this.getWBAListUsingFilterStorage()
        }
      },
        (error) => {
          let obj = error.error.errors;
          if (obj) {
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
          }
        }
      );
  }

  //contact State select dropdown
  public onChangeForContactState(event: any) {
    this.selectedContactStateFilterValue = event.toString()
  }


  public onChangeForLob(event: any) {
    //state is not in filter
    if (event.length == 0) {
      var lobIndex = this.filterValue.filters.findIndex((x: any) => x.field == "MasterBusinessLine")
      if (lobIndex > -1)
        this.filterValue.filters.splice(lobIndex, 1)
    }
    //state find in filter
    if (this.filterValue.filters.length > 0 || event !== null || event !== undefined) {
      var lobIndex = this.filterValue.filters.findIndex((x: any) => x.field == "MasterBusinessLine")
      if (lobIndex > -1)
        this.filterValue.filters.splice(lobIndex, 1)
      this.selectedLobFilterValue = event.toString()

    }
  }

  public onChangeForState(event: any) {
    //state is not in filter
    if (event.length == 0) {
      var stateIndex = this.filterValue.filters.findIndex((x: any) => x.field == "State")
      if (stateIndex > -1)
        this.filterValue.filters.splice(stateIndex, 1)
    }
    //state find in filter
    if (this.filterValue.filters.length > 0 || event !== null || event !== undefined) {
      var stateIndex = this.filterValue.filters.findIndex((x: any) => x.field == "State")
      if (stateIndex > -1)
        this.filterValue.filters.splice(stateIndex, 1)
      this.selectedStateFilterValue = event.toString()

    }

  }

  sortingPaginationStorage: any;
  public sortChange(sort: SortDescriptor[]): void {
    this.skip = 0;
    this.pageNo = 1;
    this.sort = sort;
    this.totalCount = this.finalWBAList.total;
    this.selectResetArr["sorting"] = true;
    var wbaFilterStorageforCheckedRow = JSON.parse(sessionStorage.getItem('wbaFilterStorageforCheckedRow')!)
    if (wbaFilterStorageforCheckedRow) {
      this.finalWBAList.data = orderBy(wbaFilterStorageforCheckedRow, this.sort);
      this.finalWBAList.total = wbaFilterStorageforCheckedRow.length;
      let datastorage = {
        sort: this.sort
      }
      sessionStorage.setItem('SortingPageStorage', JSON.stringify(datastorage));
    }
    else {

      this.loadData(this.selectedListId, false, true);
    }
  }

  paginationChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.pageNo = (event.take + event.skip) / event.take;
    this.totalCount = this.finalWBAList.total;
    this.isPageChanged = true;
    //For storage value
    var wbaFilterStorageforCheckedRow = JSON.parse(sessionStorage.getItem('wbaFilterStorageforCheckedRow')!)
    if (wbaFilterStorageforCheckedRow) {
      this.finalWBAList.data = wbaFilterStorageforCheckedRow.slice(this.skip, this.skip + this.pageSize);
      this.finalWBAList.total = wbaFilterStorageforCheckedRow.length;
      this.checkboxCheckedData = wbaFilterStorageforCheckedRow;
      this.unHideFlagForCheckBox = false;
    }
    else {
      this.loadData(this.selectedListId, false, true);
    }
  }

  checkData(value: CompositeFilterDescriptor) {
    return JSON.stringify(value) === JSON.stringify(this.defaultFilter) ? "" : JSON.stringify(value);
  }

  setDataForPagination(listId: number, request: any, signalR = false) {
    this.gridFilters = this.convertFormattingInDateString(this.gridFilters);

    this.isGridFilter = true;
    this.selectedDefaultList = this.selectedListId.toString();
    if (signalR) { this.totalCountMaster = this.totalRecords; }
    if (signalR || (listId) != 0) {
      request.totalCount = this.totalRecords;
      sessionStorage.setItem('wbaFilterStorage', JSON.stringify(request));
    }

    this.closebutton.nativeElement.click();

    this.convertingToDateType();
    const selectedIds = new Set(this.mySelection);


    let _masterWBAList = this.whiteBoardActivityList;
    if (signalR == true && listId == 0) {
      this.finalWBAList.total = this.totalRecords;
      sessionStorage.setItem('wbaEmptyDataStorage', 'false');
      if (signalR && this.totalRecords == 0) {
        sessionStorage.setItem('wbaEmptyDataStorage', 'true');
        this.finalWBAList.data = [];
        this.ngUnsubscribe.next();
      }
    }
    else if (signalR == false && listId == 0) {
      this.finalWBAList.data = _masterWBAList;
      this.finalWBAList.total = this.totalRecords > 0 ? this.totalRecords : this.finalWBAList.total;
      // checking selected item
      if (this.mySelection) {
        this.finalWBAList.data.forEach((x: any) => {
          x.isSelected = selectedIds.has(x.id);
        });
      }
    } else {
      this.finalWBAList.data = _masterWBAList;
      this.finalWBAList.total = this.totalRecords >= 0 ? this.totalRecords : this.finalWBAList.total;
      if (this.mySelection) {
        this.finalWBAList.data.forEach((x: any) => {
          x.isSelected = selectedIds.has(x.id);
        });
      }
    }

    this.filterBlock = true;
    if (this.isPageChanged) {
      // let selectedWbaPage: any;
      // this.finalWBAList.data.forEach((x: any) => selectedWbaPage = this.checkboxCheckedData.find((res: any) => res == x.id));
      if (!this.isAllWbaSelected) this.isAllWbaSelected = true;
      if (this.isAllWbaSelected) this.isAllWbaSelected = false;
    }
  }

  loadData(listId: number, signalR = false, saveInDB = false, duplicated = false) {
    let __tempFilter: any = this.convertArrayToString(this.filterValueCopy);
    let _tempFilter: any = this.convertEqualsIntoContains(__tempFilter);
    this.filterValueCopy = this.convertStringToBool(this.filterValueCopy);
    let temgridFilter: any = this.gridFilters;
    temgridFilter = this.convertDateStringInFormatting(this.gridFilters);
    let request: any;
    if (this.selectedListId == 0) {
      request = {
        listId: listId,
        sortDir: this.sort[0]?.dir,
        sortCol: this.sort[0]?.field,
        searchText: this.searchText,
        pageNumber: this.pageNo,
        pageSize: this.pageSize,
        filterJson: _tempFilter.filters != null && _tempFilter.filters.length > 0 ? JSON.stringify(this.filterValueCopy) : '',
        gridFilterJson: temgridFilter.filters != null && temgridFilter.filters.length > 0 ? JSON.stringify(temgridFilter) : "",
        totalCount: signalR ? -1 : this.totalCount,
        UserId: this.controlId,
      }
    } else {
      request = {
        listId: this.selectedListId,
        sortDir: this.sort[0]?.dir,
        sortCol: this.sort[0]?.field,
        searchText: this.searchText,
        pageNumber: this.pageNo,
        pageSize: this.pageSize,
        filterJson: _tempFilter.filters != null && _tempFilter.filters.length > 0 ? JSON.stringify(this.filterValueCopy) : '',
        gridFilterJson: temgridFilter.filters != null && temgridFilter.filters.length > 0 ? JSON.stringify(temgridFilter) : "",
        totalCount: signalR ? -1 : 0,
        UserId: this.controlId,
        LoggedUserId: this.UserIdLogged
      }
    }
    if (listId.toString() == '0' && request.totalCount == 0) this.loadData(this.selectedListId, true)
    if (request.totalCount != '-1') sessionStorage.setItem('wbaFilterStorage', JSON.stringify(request));
    if (saveInDB && request.totalCount != '-1') {
      this.wbaFilterJsonPayload = JSON.stringify(request);
      this.saveUserMasterGridColumn(false);
    }

    if (duplicated) {
      this.setDataForPagination(listId, request)
    }
    else {
      this._whiteBoardActivityService.wbaPagination(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe((resp: any) => {
       this.gridFilters = this.convertFormattingInDateString(this.gridFilters);

       this.isGridFilter = true;
       this.selectedDefaultList = this.selectedListId.toString();
       if (signalR) { this.totalCountMaster = resp.totalRecord; }
       if (signalR || (listId) != 0) {
         request.totalCount = resp.totalRecord;
         sessionStorage.setItem('wbaFilterStorage', JSON.stringify(request));
       }

       if (resp && resp.items.length > 0) this.whiteBoardActivityList = resp.items[0].whiteBoardActivityList;
       this.closebutton.nativeElement.click();


       this.convertingToDateType();
       const selectedIds = new Set(this.mySelection);


       let _masterWBAList = this.whiteBoardActivityList;
       if (signalR == true && listId == 0) {
         this.finalWBAList.total = resp.totalRecord;
         sessionStorage.setItem('wbaEmptyDataStorage', 'false');
         if (signalR && resp.totalRecord == 0) {
           sessionStorage.setItem('wbaEmptyDataStorage', 'true');
           this.finalWBAList.data = [];
           this.ngUnsubscribe.next();
         }
       }
       else if (signalR == false && listId == 0) {
         this.finalWBAList.data = _masterWBAList;
         this.finalWBAList.total = resp.totalRecord > 0 ? resp.totalRecord : this.finalWBAList.total;
         // checking selected item
         if (this.mySelection) {
           this.finalWBAList.data.forEach((x: any) => {
             x.isSelected = selectedIds.has(x.id);
           });
         }
       } else {
         this.finalWBAList.data = _masterWBAList;
         this.finalWBAList.total = resp.totalRecord >= 0 ? resp.totalRecord : this.finalWBAList.total;
         if (this.mySelection) {
           this.finalWBAList.data.forEach((x: any) => {
             x.isSelected = selectedIds.has(x.id);
           });
         }
       }

       this.filterBlock = true;
       if (this.isPageChanged) {
         // let selectedWbaPage: any;
         // this.finalWBAList.data.forEach((x: any) => selectedWbaPage = this.checkboxCheckedData.find((res: any) => res == x.id));
         if (!this.isAllWbaSelected) this.isAllWbaSelected = true;
         if (this.isAllWbaSelected) this.isAllWbaSelected = false;
       }
      },
        (error: any) => {
          this.filterBlock = true;
          this.gridFilters = this.convertFormattingInDateString(this.gridFilters)
          if (error.status != 200 && this.unAthorizesd) {
            this.showError = true;
            return
          }
          else {
            let errorMessage =
              'Error : ' + error.message + ', Status: ' + error.status;
            this.errorList = [];
            this.showError = true;
            this.errorList.push(errorMessage);
          }
        })
    }
  }

  public onApplicationChange(event: any): void {
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "application")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "application",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "application",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  //for grid column bussiness status
  public onBussinessStatusChange(event: any): void {
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "masterBusinessStatus")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "masterBusinessStatus",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "masterBusinessStatus",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }
  public onLossRunChange(event: any): void {
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "lossRun")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "lossRun",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "lossRun",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  public onAccordStatusChange(event: any): void {
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "acordStatus")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "acordStatus",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "acordStatus",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  public onResultListChange(event: any): void {
    if (event == 'Open') {
      event = 'Null'
    }
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "epicStatusName")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "epicStatusName",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "epicStatusName",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  public onProgressListChange(event: any): void {
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "progressName")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "progressName",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "progressName",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  public wbaOwnerListChange(event: any): void {
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "ownerUserName")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "ownerUserName",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "ownerUserName",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }
  public onChange(event: any) {
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "state")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "state",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "state",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }


  public wbaMarketingListChange(event: any): void {
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "marketingManager")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "marketingManager",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "marketingManager",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  public wbaAcctManagerListChange(event: any): void {
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "accountManagerUserName")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "accountManagerUserName",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "accountManagerUserName",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  public onLineOfBusinessChange(event: any): void {
    console.log("eeeli", event)
    const uniqueValues = new Set(event.join(',').split(','));
    const resultString = Array.from(uniqueValues).join(',');
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "masterBusinessLine")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "masterBusinessLine",
        operator: "contains",
        value: resultString
      }
      if (event.length > 0) this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "masterBusinessLine",
        operator: "contains",
        value: resultString
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    // this.$searchingText.next();
  }


  //policy type
  public onPolicyTypeChange(event: any): void {
    if (this.gridFilters.filters == undefined) this.gridFilters = { logic: 'and', filters: [] };
    if (this.gridFilters.filters.length > 0) {
      var stateIndex = this.gridFilters.filters.findIndex((x: any) => x.field == "policyType")
      if (stateIndex > -1)
        this.gridFilters.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "policyType",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0 && event[0] != '') this.gridFilters.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "policyType",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilters.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  getWBAListUsingFilterStorage() {
    // let request = JSON.parse(sessionStorage.getItem('wbaFilterStorage')!);

    let request = JSON.parse(this.wbaFilterJsonPayload);

    request.UserId = this.controlId;
    request.LoggedUserId = this.UserIdLogged;
    request.totalCount = 0;
    request.pageNumber = 1;
    this.pageNo = 1;
    sessionStorage.setItem('wbaFilterStorage', JSON.stringify(request));
    let apiCall = this._whiteBoardActivityService.wbaPagination(request).subscribe(
      (resp: any) => {
        this.totalRecords = resp.totalRecord;
        if (resp) {
          if (this.selectedListId.toString() == '0' && request.totalCount == 0) this.loadData(this.selectedListId, true, false, true);
          this.selectedDefaultList = this.selectedListId ? this.selectedListId.toString() : '0'
          this.masterWBAList = resp.items[0].whiteBoardActivityList;
          //adding key checkedRowForExport
          if (request.totalCount > 0 || this.selectedListId != 0) this.finalWBAList.total = resp.totalRecord;
          this.finalWBAList.data = this.masterWBAList;
          if (this.mySelection) {
            this.finalWBAList.data.forEach((x: any) => {
              this.mySelection.forEach((y: any) => {
                if (x.id == y) {
                  x.isSelected = true;
                }
              })
            })
          }
        }
        if (this.onloadApi == true && resp) {
          this.onloadApi = false;
          // this.getFilterJSON();
          // this.getWhiteBoardList(false);
          // this.getBusinessLineList();
          // this.getProspectStates();
          // this.getBusinessStatusList();
          // this.getResultList();
          // this.getProgressList();
          // this.getProspectIndustry();
          // this.getPolicyTypeList();
          // this.getUserList();
        }
        //this.setDataUsingFilterStorage();
      },
      (error: any) => {
        if (error.error.status != 200 && this.unAthorizesd) {
          this.showError = true;
          return
        }
        else {
          let errorMessage =
            'Error : ' + error.message + ', Status: ' + error.status;
          this.errorList = [];
          this.showError = true;
          this.errorList.push(errorMessage);
        }
      }
    );
    if (sessionStorage.getItem('wbaEmptyDataStorage') == 'true') {
      if (this.onloadApi == true) {
        this.onloadApi = false;
        // this.getFilterJSON();
        // this.getWhiteBoardList(false);
        // this.getBusinessLineList();
        // this.getProspectStates();
        // this.getBusinessStatusList();
        // this.getResultList();
        // this.getProgressList();
        // this.getProspectIndustry();
        // this.getPolicyTypeList();
        // this.getUserList();
      }
      this.setDataUsingFilterStorage(); apiCall.unsubscribe();
      this.selectedDefaultList = this.selectedListId.toString();
    }

  }


  setDataUsingFilterStorage() {
    if (this.filterData == undefined || this.filterData == null)
      if (this.wbaFilterJsonPayload) this.filterData = JSON.parse(this.wbaFilterJsonPayload!)
    if (this.filterData) {
      if (this.filterData.filterJson) {
        this.filterValue = JSON.parse(this.filterData.filterJson);
        this.filterValueCopy = JSON.parse(this.filterData.filterJson);
        this.convertContainsIntoEquals(this.filterValue);
        this.filterValueForState = JSON.parse(this.filterData.filterJson).filters.filter((x: any) => x.field == "State")[0]?.value;
        this.filterValueArrayState = this.filterValueForState?.split(',')
        this.filterValueForLob = JSON.parse(this.filterData.filterJson).filters.filter((x: any) => x.field == "MasterBusinessLine")[0]?.value
        this.filterValueArrayLob = this.filterValueForLob?.split(',');
        this.filterValueForContact = JSON.parse(this.filterData.filterJson).filters.filter((x: any) => x.field == "Office1State")[0]?.value
        this.filterValueArraycontactState = this.filterValueForContact?.split(',');
        this.EpicStatusFilterForResult = JSON.parse(this.filterData.filterJson).filters.filter((x: any) => x.field == "EpicStatusName")[0]?.value;
        if (this.EpicStatusFilterForResult == "Null") {
          this.EpicStatusFilterForResult = "Open"
        }
        if (this.EpicStatusFilterForResult) {
          let commaValue1 = false, commaValue2 = false;
          if (this.EpicStatusFilterForResult.includes('Not Quoted,Not Bound')) {
            this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/Not Quoted,Not Bound/g, "");
            if (this.EpicStatusFilterForResult.includes(',,')) {
              this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/,,/g, ",");
            }
            commaValue1 = true;
          }
          if (this.EpicStatusFilterForResult.includes('Quoted,Not Bound')) {
            this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/Quoted,Not Bound/g, "");
            if (this.EpicStatusFilterForResult.includes(',,')) {
              this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/,,/g, ",");
            }
            commaValue2 = true;
          }
          this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/(\s*,?\s*)*$/, "");

          this.filterValue.filters.forEach((x: any) => {
            if (x.field == "EpicStatusName") {
              x.value = this.EpicStatusFilterForResult?.split(',');
              if (commaValue1) {
                x.value.push("Not Quoted,Not Bound")
              }
              if (commaValue2) {
                x.value.push("Quoted,Not Bound")
              }
            }
          })

        }
      }

      this.gridFilters = this.filterData.gridFilterJson ? this.convertFormattingInDateString(JSON.parse(this.filterData.gridFilterJson)) : "";
      this.searchText = this.filterData.searchText;

      //for state grid filter
      if (this.filterData.gridFilterJson) {
        this.gridFilterForState = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "state")[0]?.value;
        this.gridFilterArrayState = this.gridFilterForState?.split(',');
        this.gridFilterForLob = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "masterBusinessLine")[0]?.value;
        this.gridFilterArrayLob = this.gridFilterForLob?.split(',')
        this.gridFilterForAcordStatus = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "acordStatus")[0]?.value;
        this.gridFilterArrayAcordStatus = this.gridFilterForAcordStatus?.split(',');
        this.gridFilterForPolicyType = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "policyType")[0]?.value;
        this.gridFilterArrayPolicyType = this.gridFilterForPolicyType?.split(',')

        this.gridFilterForResult = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "epicStatusName")[0]?.value;
        if (this.gridFilterForResult == "Null") {
          this.gridFilterForResult = "Open"
        }
        if (this.gridFilterForResult) {
          var oldString = "stackoverflow";
          var str = oldString.replace(/stackover/g, "NO");
          // $scope.newString = str;
          let commaValue1 = false, commaValue2 = false;
          if (this.gridFilterForResult.includes('Not Quoted,Not Bound')) {
            this.gridFilterForResult = this.gridFilterForResult.replace(/Not Quoted,Not Bound/g, "");
            if (this.gridFilterForResult.includes(',,')) {
              this.gridFilterForResult = this.gridFilterForResult.replace(/,,/g, ",");
            }
            commaValue1 = true;
          }
          if (this.gridFilterForResult.includes('Quoted,Not Bound')) {
            this.gridFilterForResult = this.gridFilterForResult.replace(/Quoted,Not Bound/g, "");
            if (this.gridFilterForResult.includes(',,')) {
              this.gridFilterForResult = this.gridFilterForResult.replace(/,,/g, ",");
            }
            commaValue2 = true;
          }
          this.gridFilterForResult = this.gridFilterForResult.replace(/(\s*,?\s*)*$/, "");

          this.gridFilterArrayResult = this.gridFilterForResult?.split(',');

          if (commaValue1) {
            this.gridFilterArrayResult.push("Not Quoted,Not Bound")
          }
          if (commaValue2) {
            this.gridFilterArrayResult.push("Quoted,Not Bound")
          }
        }

        this.gridFilterForLossRun = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "lossRun")[0]?.value;
        this.gridFilterArrayLossRun = this.gridFilterForLossRun?.split(',');
        this.gridFilterForApplication = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "application")[0]?.value;
        this.gridFilterArrayApplication = this.gridFilterForApplication?.split(',');
        this.gridFilterForProgress = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "progressName")[0]?.value;
        this.gridFilterArrayProgress = this.gridFilterForProgress?.split(',');
        this.gridFilterForwbaOwnerList = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "ownerUserName")[0]?.value;
        this.gridOwnerWbaListArray = this.gridFilterForwbaOwnerList?.split(',');
        this.gridFilterForBussinessStatus = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "masterBusinessStatus")[0]?.value;
        this.gridFilterArrayBussinessStatus = this.gridFilterForBussinessStatus?.split(',');
        let marketingManagerData = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "marketingManager")[0]?.value;
        this.gridmarketingManagerListArray = marketingManagerData?.split(',');
        let accountManagerData = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "accountManagerUserName")[0]?.value;
        this.gridAcctManagerListArray = accountManagerData?.split(',');
        // grid filter for date value set here
        let gridFilterForeffectiveDate = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "effectiveDate")[0]?.value;
        let effectiveDate = new Date(gridFilterForeffectiveDate)
        let gridFilterForproposalDate = JSON.parse(this.filterData.gridFilterJson).filters.filter((x: any) => x.field == "proposalDate")[0]?.value;
        let proposalDate = new Date(gridFilterForproposalDate)
        // this.gridFilters.filters.map((obj: any) => {
        //   if (obj.field == 'effectiveDate') {
        //     obj.value = effectiveDate
        //   }
        //   if (obj.field == 'proposalDate') {
        //     obj.value = proposalDate
        //   }
        // })
      }
    }

  }

  //binding create list
  getWhiteBoardList(callList: boolean = true) {
    this._whiteBoardActivityService.getWbaList().subscribe((resp: any) => {
      this.defaultList = resp.list;



      this.defaultList.forEach((item: any) => {
        let groupIndex = this.prospectCreateList.findIndex(
          (ob: any) => ob.label === item.listType
        );

        let listObj = { label: item.listName, value: item.listId.toString() };
        if (groupIndex === -1) {
          // group has not found
          let Obj = {
            label: item.listType,
            value: item.listType,
            items: [listObj],
          };
          this.prospectCreateList.push(Obj);
        } else {
          // group has found
          this.prospectCreateList[groupIndex].items.push(listObj);
        }
      });
      //calling onCreateListChange
      if (callList) this.onCreateListChange({ value: this.selectedListId }, 'pageload');
      //this.selectedDefaultList = { value: this.selectedListId.toString() };

    });
  }

  //To hide column filters
  columnFilter(value: boolean) {
    this.isFilter = !value;
  }

  showExecutiveSummaryWarningBox() {
    this._warningBox.message = this._userMessages.wbaExecutiveSummaryWarningMessage;
    this._warningBox.isVisible = true;
  }

  warningBoxCancel() {
    this._warningBox.isVisible = false;
  }

  getWhiteBoardDetails(wbaId: any) {
    this._whiteBoardActivityService.getWBAById(wbaId).subscribe(resp => {
      this.wbaView = resp.whiteBoardActivityInfo;
      console.log("isBorDefined", this.wbaView?.isBorDefined)
      console.log("isBorDefined", this.wbaView?.outcome)
      console.log("isBorDefined", this.wbaView?.wbaStatusDescription)
      console.log("isBorDefined", this.wbaView?.isBorDefined)
      if (this.wbaView.auditRun || this.wbaView.masterBusinessLine === "Specialty") {
        sessionStorage.setItem(environment.storageVariables.wbaId, wbaId);
        this._router.navigateByUrl('/' + this._navigationPath.getWhiteboardsUrl() + '/' + this._navigationPath.getexecutiveSummarysUrl() + '?listId=' + this.selectedListId + '&initiated=' + 'WhiteboardList')
      }
      else {
        this.showExecutiveSummaryWarningBox();
      }
    })
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });

  }
  //code added
  //multiselect ngmodel
  getMultiselectValue(currentItem: any) {
    typeof currentItem.value == 'string' && currentItem.value != ""
      ? (currentItem.value = currentItem.value.split(','))
      : currentItem.value;
    return currentItem.value;
  }

  //multiselect for industry
  getMultiselectValueByid(currentItem: any) {
    typeof currentItem.value == 'string' && currentItem.value != ""
      ? (currentItem.value = currentItem.value.split(',').map(Number))
      : currentItem.value;
    return currentItem.value;
  }

  //numerical ngmodel
  getNumericValue(currentItem: any) {
    if (currentItem.value) {
      return +currentItem.value;
    } else {
      return currentItem.value;
    }
  }

  getNumericValueEXP(currentItem: any) {

    if (currentItem.value) {
      //let expValue = this.ValidateKey.formatDecimalNumber(currentItem.value.toString());
      let _curValue = currentItem.value.toString();
      let index0 = _curValue.charAt(0)
      let index1 = '.'
      let index2 = _curValue.charAt(_curValue.includes('.') ? 2 : 1)
      let index3 = _curValue.charAt(_curValue.includes('.') ? 3 : 2)
      let expValue = index0 + index1 + index2 + index3;
      return (+expValue);
    } else {
      return currentItem.value;
    }
  }

  //convert decimal to int
  public onChangeForNumber(
    event: any,
    currentItem: FilterDescriptor
  ) {
    currentItem.value = event?.toFixed();
  }

  //multiselect select dropdown
  public onChangeForMultiselect(
    event: any,
    currentItem: FilterDescriptor
  ) {
    currentItem.value = event;
  }

  //covert array to string
  convertArrayToString(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertArrayToString(x);
        } else {
          x.value = x?.value?.toString();
        }
      });
    }
    return json;
  }



  //convert string to bool
  convertStringToBool(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertStringToBool(x);
        } else {
          x.value = (x.value === 'true') ? true : (x.value === 'false') ? false : x.value;
        }
      });
    }
    return json;
  }

  //convert eq operator to contains operator
  convertEqualsIntoContains(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertEqualsIntoContains(x)
        }
        else {
          if (x.operator == 'eq') {
            if (x.field === 'State' || x.field === 'MasterBusinessStatus' || x.field === 'WBAStatusId'
              || x.field === 'WBARecall.Status' || x.field === 'prospectstatus.Status' || x.field === 'MasterPolicyType.line'
              || x.field === 'Office1State' || x.field === 'MasterBusinessLine' || x.field === 'ProspectIndustry.IndustryName' || x.field === 'PI.IndustryName' || x.field === 'ProgressName' || x.field === 'EpicStatusName' || x.field === 'ProfitCenter' || x.field === 'WBA.OwnerUserName'
              || x.field == 'wba.OriginalProducerName' || x.field == "Prospect.ModifiedBy" || x.field == "Prospect.CreatedBy" || x.field == "WBA.CreatedBy" || x.field == "WBA.MarketingManagerId" || x.field == "Prospect.LastContactedBy") {
              x.operator = 'contains';
            }
          } else if (x.operator == 'neq') {
            if (x.field === 'industryclassandcodeinformation.Class.NAICS' || x.field === 'industryclassandcodeinformation.Class.SIC'
              || x.field === 'industryclassandcodeinformation.Class.PCS' || x.field === 'industryclassandcodeinformation.Class.NTEE') {
              x.operator = 'doesnotcontain'
            }
          }
        }
      })
    }
    return json;
  }

  //convert contains operator to eq operator
  convertContainsIntoEquals(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertContainsIntoEquals(x)
        }
        else {
          if (x.operator == 'contains') {
            if (x.field === 'State' || x.field === 'MasterBusinessStatus' || x.field === 'WBAStatusId'
              || x.field === 'WBARecall.Status' || x.field === 'prospectstatus.Status' || x.field === 'MasterPolicyType.line'
              || x.field === 'Office1State' || x.field === 'MasterBusinessLine' || x.field === 'ProspectIndustry.IndustryName' || x.field === 'PI.IndustryName' || x.field === 'ProgressName' || x.field === 'EpicStatusName' || x.field === 'ProfitCenter' || x.field === "WBA.OwnerUserName" || x.field == 'wba.OriginalProducerName' || x.field == "Prospect.ModifiedBy" || x.field == "Prospect.CreatedBy" || x.field == "WBA.CreatedBy" || x.field == "WBA.MarketingManagerId" || x.field == "Prospect.LastContactedBy") {
              x.operator = 'eq';
            }
          } else if (x.operator == 'doesnotcontain') {
            if (x.field === 'industryclassandcodeinformation.Class.NAICS' || x.field === 'industryclassandcodeinformation.Class.SIC'
              || x.field === 'industryclassandcodeinformation.Class.PCS' || x.field === 'industryclassandcodeinformation.Class.NTEE') {
              x.operator = 'neq';
            }
          }
        }
      })
    }
    return json;
  }

  convertDateStringInFormatting(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertDateStringInFormatting(x)
        }
        else {
          if (x.field == 'effectiveDate' || x.field == 'proposalDate') {
            x.value = this.datepipe.transform(x.value, 'yyyy-MM-dd');
          }
        }
      })
    }
    return json;
  }

  convertFormattingInDateString(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertFormattingInDateString(x)
        }
        else {
          if (x.field == 'effectiveDate' || x.field == 'proposalDate') {
            // x.value = new Date(x.value)
            let formattedDate: any = this.datepipe.transform(x.value, 'MMMM d, y');
            x.value = new Date(formattedDate);
          }
        }
      })
    }
    return json;
  }

  //for All industry dropdown
  getProspectIndustry(): void {
    this._AddprospectService.getProspectIndustry().subscribe((response: any) => {
      this.prospectIndustryList = response.prospectIndustryList;
    });
  }

  reOpenModel(selectedWBAId: any) {
    this.reasonForReopenWBA = "";
    this.id = selectedWBAId;
    this.callFrom = 'Reopen'
    this._confirmBox.isVisible = true;
  }

  saveReason() {
    if (!this.reasonForReopenWBA) {
      this.invalidReasonForReopenWBA = true;
      return
    }
    let saveData = {
      wbaId: this.id,
      description: this.reasonForReopenWBA
    }
    this._addWBAService.saveReasonForReopenWBA(saveData).subscribe((res: any) => {
      if (res) {
        this._confirmBox.isVisible = false;
        this.reasonForReopenWBA = "";
        this.loadData(this.selectedListId);
      }
    },
      (error: any) => {
        if (error) {
        }
      })
  }

  //to open 'Clone Wba' model
  openCloneBorModal(item: any) {
    const modalRef = this.modalService.open(CloneBorComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    let data = item;
    modalRef.componentInstance.fromCloneData = data;
    modalRef.componentInstance.fromCloneDataListId = this.selectedListId;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      //this.getContactList(this.selectedListId);

    })
    modalRef.closed.subscribe((resp) => {

    });
  }

  //Clone wba
  CreateClone(selectedWBAId: any) {
    this.id = selectedWBAId;
    let saveData = {
      wbaId: this.id
    }
    this._addWBAService.saveCloneWBA(saveData).subscribe((res: any) => {
      if (res) {
        if (res.message) {
          this._confirmBoxForClone.message = res.message;
          this._confirmBoxForClone.isVisible = true;
        } else {
          this._confirmBoxForClone.message = 'The clone is successfully created with WBA ID ' + " - " + "[" + res.wbaId + "]";
          this._confirmBoxForClone.isVisible = true;
        }
        //this.loadData(this.selectedListId);
        //this._router.navigate(['/' + this._navigationPath.getWhiteboardsUrl() + '/' + this._navigationPath.getwhiteboardsApplicationUrl(),], { queryParams: { wbaId: res } });
      }
    },
      (error: any) => {
        if (error) {
        }
      })
  }

  //Select and Unselect a prospect from list.
  changeSelection(item: any) {
    item.isSelected = !item.isSelected;
    if (item.isSelected == true) {
      this.checkboxCheckedData.push(item);
    }
    if (item.isSelected == false) {
      this.checkboxCheckedData.forEach((y: any) => {
        if (y.id == item.id) {
          let checkSelectionIndex = this.checkboxCheckedData.findIndex((z: any) => z.id == item.id)
          if (checkSelectionIndex > -1)
            this.checkboxCheckedData.splice(checkSelectionIndex, 1);
          let indexSelection = this.mySelection.findIndex((x: any) => x == item.id)
          if (indexSelection > -1)
            this.mySelection.splice(indexSelection, 1);
        }
      })
    }
    sessionStorage.setItem('mySelection', JSON.stringify(this.mySelection));
    sessionStorage.setItem('checkboxselectedWithoutHide', JSON.stringify(this.checkboxCheckedData));
  }

  //Select and Unselect all prospect in list.
  changeAllSelection() {
    this.isAllWbaSelected = !this.isAllWbaSelected;
    if (this.isAllWbaSelected) {
      if (this.finalWBAList.data) {
        this.finalWBAList.data.forEach((x: any) => {
          x.isSelected = this.isAllWbaSelected;
          this.checkboxCheckedData.push(x);
        });
      }
      sessionStorage.setItem('mySelection', JSON.stringify(this.mySelection));
      sessionStorage.setItem('checkboxselectedWithoutHide', JSON.stringify(this.checkboxCheckedData));
    }
    if (this.isAllWbaSelected == false) {
      if (this.finalWBAList.data) {
        this.finalWBAList.data.forEach((x: any) => {
          this.checkboxCheckedData.forEach((y: any) => {
            if (x.id == y.id) x.isSelected = this.isAllWbaSelected;
          });
        });
      }
      this.checkboxCheckedData = [];
      this.mySelection = [];
      sessionStorage.removeItem('wbaFilterStorageforCheckedRow');
      sessionStorage.removeItem('mySelection');
      sessionStorage.removeItem('checkboxselectedWithoutHide');
      sessionStorage.removeItem('SortingPageStorage');
      this.skip = 0;
      this.totalCount = 0;
      this.pageNo = 1;
      if (parseInt(this.selectedListId) > 0) {
        this.getQueryById(this.selectedListId)
      }
      else {
        this.loadData(this.selectedListId);
      }
    }
  }


  //for Hide Unselected
  openRemoveWbaFromListPopUp(pId: any) {
    this.unHideFlagForCheckBox = false;
    // copy checked data array //
    this.skip = 0;
    this.totalCount = 0;
    this.pageNo = 0;
    this.isAllWbaSelected = true;
    let temgridData = JSON.parse(JSON.stringify(this.checkboxCheckedData));
    if (temgridData) {
      this.finalWBAList.data = temgridData;
      this.finalWBAList.total = temgridData.length;
    }
    sessionStorage.setItem('wbaFilterStorageforCheckedRow', JSON.stringify(this.checkboxCheckedData));

  }

  unHidewbaListData() {
    sessionStorage.removeItem('wbaFilterStorageforCheckedRow');
    this.mySelection = JSON.parse(sessionStorage.getItem('mySelection')!);
    this.checkboxCheckedData = JSON.parse(sessionStorage.getItem('checkboxselectedWithoutHide')!);
    this.skip = 0;
    this.totalCount = 0;
    this.pageNo = 1;
    this.unHideFlagForCheckBox = true;
    if (parseInt(this.selectedListId) > 0) {
      this.getQueryById(this.selectedListId)
    }
    else {
      this.loadData(this.selectedListId);
    }
  }

  setStyleOfPopup() {
    // todo: i think it is redundant: fixed in styles.css. remove in all places
    setTimeout(() => {
      const animationContainer = document.querySelector('.k-animation-container.k-animation-container-shown');
      if (animationContainer) {
        const popupElement = animationContainer as HTMLElement;
        if (popupElement) {
          if (animationContainer.childNodes[0] && (animationContainer.childNodes[0] as HTMLElement).className === 'k-popup') {
            popupElement.classList.add('columnMenuFilter_wba');
            animationContainer.querySelector('kendo-grid-columnlist')?.classList.add('d-block')
            animationContainer.querySelector('.k-column-chooser-title')?.classList.add('d-block')
          }
        }
      }

    }, 200);
  }


  clearNgModelOnColumnHide(val: any) {
    if (val == "state") {
      this.gridFilterArrayState = [];
    }
    if (val == "masterBusinessStatus") {
      this.gridFilterArrayBussinessStatus = [];
    }
    if (val == "epicStatusName") {
      this.gridFilterArrayResult = [];
    }
    if (val == "progressName") {
      this.gridFilterArrayProgress = [];
    }
    if (val == "ownerUserName") {
      this.gridOwnerWbaListArray = [];
    }
    if (val == "marketingManager") {
      this.gridmarketingManagerListArray = [];
    }
    if (val == "accountManagerUserName") {
      this.gridAcctManagerListArray = [];
    }
    if (val == "application") {
      this.gridFilterArrayApplication = [];
    }
    if (val == "lossRun") {
      this.gridFilterArrayLossRun = [];
    }
    if (val == "acordStatus") {
      this.gridFilterArrayAcordStatus = [];
    }
    if (val == "policyType") {
      this.gridFilterArrayPolicyType = [];
    }
    if (val == "masterBusinessLine") {
      this.gridFilterArrayLob = [];
    }
  }
  onColumnMenuInit(e: any) {
    this.removeFilterForhideColumn = false;
    for (let i = 0; i < e.columns.length; i++) {
      this.gridFilters?.filters?.forEach((x: any, index: any) => {
        x?.filters?.forEach((z: any) => {
          if (z.field.toLowerCase() == e.columns[i].field.toLowerCase() && e.columns[i].hidden) {
            this.isInValidNumber = true;
            this.removeFilterForhideColumn = true;
            this._exportConfirmBox.message = "Hiding a column with a filter applied will clear the filter.";
            this._exportConfirmBox.isVisible = true;
            this.gridFilters?.filters.splice(index, 1)
            if (this.gridFilters?.filters.length == 0)
              (document.getElementById('columnFilter') as HTMLInputElement).checked = false;
          }
        })
      })
    }
    let currentColumns = this.allCols || [];
    // for (let i = 0; i < e.columns.length; i++) {
    let gridColumns = e.columns;
    gridColumns.forEach((x: any) => {
      currentColumns.forEach((col: any) => {
        if (col.columnName === x.title) {
          col.isVisible = !col.isVisible
        }
      })
    })

    this.columnsOfList = currentColumns;
    let columnList = { viewColumns: currentColumns, columnOrder: this.orderedCols }
    let opts = {

      listId: this.selectedListId,
      module: "Wba",
      columnList: columnList,
      filterJson: this.wbaFilterJsonPayload
    }
    this.selectResetArr["column"] = true;

    this.userManagementService.saveGridFilterColumnList(opts).subscribe(s => { })
  }
  getDynamicColumnList() {
    this._manageListService.getDynamicColumnList(this.selectedListId, 'Wba').subscribe((resp: any) => {
      if (resp) {
        this.wbaFilterJsonPayload = resp.filterJson;
        this.setdataFilter();
        if (this.wbaFilterJsonPayload) {
          this.setDataUsingFilterStorage();
          this.getWBAListUsingFilterStorage()
        }
        else {
          this.setdataFilter();
          //First time when list id eq to 0
          this.loadData(this.selectedListId)
        }
        if (resp.selectedViewColumns && resp.selectedViewColumns.viewColumns && (resp.selectedViewColumns.viewColumns || []).length > 0) {
          this.columnsOfList = resp.selectedViewColumns.viewColumns;
        }
        else {
          this.columnsOfList = resp.masterViewColumns.viewColumns;
        }

        this.ColumnOrderMaster = resp.masterViewColumns.columnOrder
        resp.masterViewColumns.viewColumns.forEach((column: any) => {
          this.columnWidthMaster[column.columnName] = column.width;
        });

        this.columnsOfListMasterData = resp.masterViewColumns.viewColumns;


        // if (resp.selectedViewColumns && resp.selectedViewColumns.columnOrder && (resp.selectedViewColumns.columnOrder || []).length > 0) {
        //   this.orderedCols = resp.selectedViewColumns.columnOrder;
        // }
        // else {
        this.orderedCols = resp.selectedViewColumns?.columnOrder ? resp.selectedViewColumns?.columnOrder : resp.masterViewColumns?.columnOrder;
        // }
        resp.masterViewColumns.columnOrder.forEach((element: any) => {
          if (!this.orderedCols.includes(element) && element != 'Action') {
            this.orderedCols.push(element);
          }
        });
        this.selectResetArr = {};
        if (this.wbaFilterJsonPayload) {
          if (this.gridFilters?.filters?.length) this.selectResetArr["columnFilter"] = true;
          if (this.selectedListId == 0 && this.filterValue?.filters?.length) { this.selectResetArr["filter"] = true; this.isFilter1 = false }
          if (!(this.filterData && this.filterData?.sortCol == 'effectiveDate' && this.filterData.sortDir == 'desc')) this.selectResetArr["sorting"] = true;
        }
        if (this.compareVisibility(this.columnsOfList, this.columnsOfListMasterData)) this.selectResetArr["column"] = true;

        if (!this.arraysAreIdentical(this.orderedCols, this.ColumnOrderMaster)) this.selectResetArr["columnSequence"] = true;
        if (!this.areWidthsEqual(this.columnsOfList, this.columnWidthMaster)) this.selectResetArr["width"] = true;

        this.allCols = this.columnsOfList

        let cols: any[] = [];
        const columns = this.grid.columns.toArray();
        cols = columns.slice(0, 2); // Get the first two columns

        if ((this.orderedCols).length > 0) {
          const remainingColumns = this.grid.columns.toArray().slice(2); // Get the remaining columns starting from the third column
          let reorderedColumns1: any[] = [];

          this.orderedCols.forEach((title: any) => {
            const column = remainingColumns.find(item => item.title === title);
            if (column) {
              reorderedColumns1.push(column);
            }
          });
          cols = cols.concat(reorderedColumns1);
        }

        this.grid.columns.reset(cols);


      }
    });
  }


  setdataFilter() {
    if (this.wbaFilterJsonPayload) {
      this.filterData = JSON.parse(this.wbaFilterJsonPayload);
      if (this.filterData?.gridFilterJson) this.gridFilters = this.convertContainsIntoEquals(JSON.parse(this.filterData.gridFilterJson));
      if (this.filterData?.filterJson && this.selectedListId <= 0) this.filterValue = this.convertContainsIntoEquals(JSON.parse(this.filterData.filterJson));
      if (this.filterData?.filterJson && this.selectedListId <= 0) this.filterValueCopy = this.convertContainsIntoEquals(JSON.parse(this.filterData.filterJson));
      if (this.filterData?.sortCol) this.sort = [{ field: this.filterData?.sortCol, dir: this.filterData.sortDir, }];
      if (this.filterData?.pageNumber) {
        this.pageNo = this.filterData != null ? this.filterData?.pageNumber : 1;
        this.skip = this.filterData != null ? (this.filterData?.pageNumber - 1) * this.filterData?.pageSize : 0;
        this.pageSize = this.filterData?.pageSize;
      }
    } else {
      this.gridFilters = { ...this.defaultFilter };
      if (this.selectedListId <= 0) this.filterValue = { ...this.defaultFilter };
      if (this.selectedListId <= 0) this.filterValueCopy = { ...this.defaultFilter };
      this.sort = [{ field: 'effectiveDate', dir: 'desc', }];
      if (this.filterData) this.filterData.searchText = '';
      this.pageNo = 1;
      this.skip = 0;
      this.pageSize = 50;
    }
  }


  DownloadExportFile(exportType: any): void {
    let finalArr = ["ProspectID", "WBAID"]
    let _orderedCols: any = []
    //removing hidden columns from orderedCols
    this.orderedCols.forEach((y: any) => {
      this.columnsOfList.forEach((x: any) => {
        if (x.isVisible) {
          if (x.columnName != "Actions") {
            if (y == x.columnName)
              _orderedCols.push(x.columnName);
          }
        }
      })
    })
    finalArr = finalArr.concat(_orderedCols)
    if (exportType == 'All') {
      let listId = this.selectedListId;
      let data: any = {
        listId: listId,
        columnList: finalArr,
        entityType: 'wba',
        appliedFilter: this.wbaFilterJsonPayload
      };

      this._manageListService.getExportList(data).pipe().subscribe(success => {
        if (success) {
          this.isInValidNumber = true;
          this._exportConfirmBox.message = this._userMessages.exportListMessage;
          this._exportConfirmBox.isVisible = true;
        }
      })
    }
    else {
      if (this.mySelection.length)
        this.exportSelectedwba('multi');
      else
        return;
    }
  }

  confirmBoxOk() {
    this._exportConfirmBox.isVisible = false;
    if (this.removeFilterForhideColumn) {
      this.skip = 0;
      this.pageNo = 1;
      this.totalCount = 0;
      this.loadData(this.selectedListId, false, true)
    }
  }

  onColumnReorder(event: any) {
    if (event.newIndex <= 1) {
      event.preventDefault();
      return;
    }
    this.selectResetArr["columnSequence"] = true;
    this.reordered = true;
    // Save column order to local storage when columns are reordered

    let columnOrder1 = this.moveElementInArray(this.orderedCols, event.oldIndex - 2, event.newIndex - 2);
    this.orderedCols = columnOrder1

    this.saveUserMasterGridColumn();


    //localStorage.setItem("columnOrder", JSON.stringify(columnOrder));
  }

  moveElementInArray(array: any, oldIndex: number, newIndex: number) {
    if (oldIndex === newIndex || oldIndex < 0 || newIndex < 0 || oldIndex >= array.length || newIndex >= array.length) {
      return array.slice(); // Return a shallow copy of the original array if no movement is required
    }

    const elementToMove = array[oldIndex];
    const newArray = [...array]; // Create a shallow copy of the original array

    // Remove the element from the old index
    newArray.splice(oldIndex, 1);

    // Insert the element at the new index
    newArray.splice(newIndex, 0, elementToMove);

    return newArray;
  }


  exportSelectedwba(downloadFrom: any): void {
    let finalArr = ["ProspectID", "WBAID"]
    let _orderedCols: any = []
    let data: any = { idList: [], columnList: [] };
    this.mySelection.forEach((x: any) => {
      data.idList.push(x);
    })

    data.columnList = []
    this.orderedCols.forEach((y: any) => {
      this.columnsOfList.forEach((x: any) => {
        if (x.isVisible) {
          if (x.columnName != "Actions") {
            if (y == x.columnName)
              _orderedCols.push(x.columnName);
          }
        }
      })
    })
    finalArr = finalArr.concat(_orderedCols)
    data.columnList = finalArr,
      this._whiteBoardActivityService.getExportWbaList(data).pipe().subscribe((resp: any) => {
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(resp.body);

        // create name dynamically
        let cur_date = new Date();
        let listName: any;
        if (data.idList.length == 1) {
          // all prospect when no list selected in create list dropdown
          listName = "WBA List_" + this.datepipe.transform(cur_date, 'MMddyyy_HHmm') + ".csv"
        }
        else {
          //selected prospects from checkbox
          listName = "WBA List_" + this.datepipe.transform(cur_date, 'MMddyyy_HHmm') + ".csv"
        }
        downloadLink.setAttribute('download', listName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
        // this.mySelection = [];// removing selected rows css
        // this.isAllProspectSelected = false;
        // this.selectedProspectCount = 0

      });

  }

  getUserList() {
    let data = {
      "lookUpType": 'AllUser',
      "rowCount": 500
    }
    this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((resp: any) => {
      this.userList = resp.userList;
    })
  }
  columnResize(event: any) {
    this.selectResetArr["width"] = true;
    this.columnsOfList.forEach(x => {
      if (x.columnName.toLowerCase() == event[0].column.title.toLowerCase()) {
        x.width = event[0].newWidth;
      }
    })
    let columnList = { viewColumns: this.columnsOfList, columnOrder: this.orderedCols }
    let opts = {

      listId: this.selectedListId,
      module: "Wba",
      columnList: columnList,
      filterJson: this.wbaFilterJsonPayload,

    }
    this.userManagementService.saveGridFilterColumnList(opts).subscribe(s => {
    })

  }

  GridColumnWidth(colName: string): number {
    let width: number = 200;

    try {
      if (this.columnsOfList.length > 0) {
        let columnInfo = this.columnsOfList.find(x => x.columnName.toLowerCase() == colName.toLowerCase())
        if (columnInfo != undefined)
          width = columnInfo.width;
      }
    }
    catch (e) {
      width = 200
    }
    return width
  }


  resetAllfunctionality() {
    if (this.wbaFilterJsonPayload == '' || this.wbaFilterJsonPayload == undefined) this.wbaFilterJsonPayload = sessionStorage.getItem('wbaFilterStorage');

    let temppayload = JSON.parse(this.wbaFilterJsonPayload ? this.wbaFilterJsonPayload : '{}');
    let value = this.selectResetArr;
    if (value['sorting']) {
      this.sort = [
        {
          field: 'effectiveDate',
          dir: 'desc',
        }
      ];

      this.skip = 0;
      this.pageNo = 1;
      this.sort = this.sort;
      this.totalCount = this.finalWBAList.total;
      temppayload.sort = "effectiveDate"
      temppayload.sortDir = 'desc'
    }
    if (value["filter"]) {
      this.pageNo = 1;
      this.skip = 0;
      this.pageSize = 50;
      this.searchText = "";
      this.totalCount = 0;
      temppayload.searchText = "";
      if (this.filterData) this.filterData.searchText = "";
      if (this.selectedListId == 0) {
        this.filterValue = { logic: 'and', filters: [] };
        this.filterValueCopy = { logic: 'and', filters: [] };
        temppayload.filterJson = ""
      }
      this.isFilter1 = false
    }
    if (value["columnFilter"]) {
      this.pageNo = 1;
      this.skip = 0;
      this.pageSize = 50;
      this.totalCount = 0;
      this.gridFilters = { logic: 'and', filters: [], };
      temppayload.gridFilterJson = ""
      this.clearValueMultiselect();
    }


    if (value["columnSequence"]) {
      this.orderedCols = this.ColumnOrderMaster;
      let cols: any[] = [];
      const columns = this.grid.columns.toArray();
      cols = columns.slice(0, 2); // Get the first two columns
      if ((this.orderedCols).length > 0) {
        const remainingColumns = this.grid.columns.toArray().slice(2); // Get the remaining columns starting from the third column
        let reorderedColumns1: any[] = [];

        this.orderedCols.forEach((title: any) => {
          const column = remainingColumns.find(item => item.title === title);
          if (column) {
            reorderedColumns1.push(column);
          }
        });
        cols = cols.concat(reorderedColumns1);
      }

      this.grid.columns.reset(cols);
    }
    if (value["width"]) {
      this.columnsOfList.forEach(s => s.width = this.columnWidthMaster[s.columnName])
    }

    if (value["column"]) {
      this.columnsOfList = this.columnsOfListMasterData;
    }
    this.wbaFilterJsonPayload = JSON.stringify(temppayload);
    if (this.wbaFilterJsonPayload == '{}') this.wbaFilterJsonPayload = "";
    this.loadData(+(this.selectedId), false, true)
    this.selectResetArr = {};
    (document.getElementById('columnSequence') as HTMLInputElement).checked = false;
    (document.getElementById('columnFilter') as HTMLInputElement).checked = false;
    (document.getElementById('filter') as HTMLInputElement).checked = false;
    (document.getElementById('sorting') as HTMLInputElement).checked = false;
    (document.getElementById('width') as HTMLInputElement).checked = false;
    (document.getElementById('column') as HTMLInputElement).checked = false;
    (document.getElementById('resetAll') as HTMLInputElement).checked = false;
    if (this.reordered) this.reloadComponent(true);
    (document.getElementById('dropdownMenuButton3') as HTMLButtonElement).click();

  }


  onChangeResetAll(event: any) {
    if (event.target.checked) {
      this.selectResetArr = {
        sorting: true,
        filter: true,
        columnFilter: true,
        columnSequence: true,
        width: true,
        column: true
      };
      this.isFilter1 = false;
      (document.getElementById('columnSequence') as HTMLInputElement).checked = true;
      (document.getElementById('columnFilter') as HTMLInputElement).checked = true;
      (document.getElementById('filter') as HTMLInputElement).checked = true;
      (document.getElementById('sorting') as HTMLInputElement).checked = true;
      (document.getElementById('width') as HTMLInputElement).checked = true;
      (document.getElementById('column') as HTMLInputElement).checked = true;
    } else {
      (document.getElementById('columnSequence') as HTMLInputElement).checked = false;
      (document.getElementById('columnFilter') as HTMLInputElement).checked = false;
      (document.getElementById('filter') as HTMLInputElement).checked = false;
      (document.getElementById('sorting') as HTMLInputElement).checked = false;
      (document.getElementById('width') as HTMLInputElement).checked = false;
      (document.getElementById('column') as HTMLInputElement).checked = false;
      (document.getElementById('resetAll') as HTMLInputElement).checked = false;
      this.selectResetArr = {}
    }
  }


  //save user preference
  saveUserMasterGridColumn(call: boolean = false) {

    let columnList = { viewColumns: this.columnsOfList, columnOrder: this.orderedCols }
    let opts = {

      listId: this.selectedListId,
      module: "Wba",
      columnList: columnList,
      filterJson: this.wbaFilterJsonPayload,

    }
    this.userManagementService.saveGridFilterColumnList(opts).subscribe(s => {
      if (call) this.getDynamicColumnList()
    })
  }

  //To reload component internally
  reloadComponent(self: boolean, urlToNavigateTo?: string) {
    //skipLocationChange:true means dont update the url to / when navigating
    console.log("Current route I am on:", this.router.url);
    const url = self ? this.router.url : urlToNavigateTo;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {
        console.log(`After navigation I am on:${this.router.url}`)
      })
    })
  }

  onCellDoubleClick(event: any) {
    this.router.navigateByUrl(`/whiteboard-activities/application?wbaId=${this.currentClickRow.id}&listId=${this.selectedListId}&prospectId=${this.currentClickRow.prospectId}`);
  }

  onCellClick(event: any) {
    this.currentClickRow = event.dataItem;
  }

  singleClickOnCompanyName(dataitem: any) {
    this.router.navigateByUrl(`prospects/viewprospect?prospectId=${dataitem.prospectId}&listId=${this.selectedListId}&initiated=whiteboard`);
  }

  onCellClickProspect(dataitem: any = "", event: any, navigate = false) {
    const anchorTag = document.getElementById(dataitem.id + '_name') as HTMLAnchorElement;
    if (anchorTag) {
      // Update the href attribute with the new URL
      anchorTag.href = "javascript:void(0)";
    }
    const currentClickTime = new Date().getTime();
    const timeDifference = currentClickTime - this.lastClickTime;
    const isDoubleClick = this.currentClickRow === event.dataItem && timeDifference < 300; // Adjust time interval as needed (milliseconds)

    this.currentClickRow = event.dataItem;
    this.lastClickTime = currentClickTime;

    if (isDoubleClick) {
      this.router.navigateByUrl(`/whiteboard-activities/application?wbaId=${dataitem.id}&listId=${this.selectedListId}&prospectId=${dataitem.prospectId}`);
      return
    }
    else {
      setTimeout(() => {
        console.log("single")
        if (this.currentClickRow === event.dataItem && !(window.location.href.includes('application')) && navigate) {
          this.router.navigateByUrl(`prospects/viewprospect?prospectId=${dataitem.prospectId}&listId=${this.selectedListId}&initiated=whiteboard`);
        }
      }, 400);
      return

    }
  }

  onContextMenu(event: MouseEvent, dataItem: any) {
    const anchorTag = document.getElementById(dataItem.id + '_name') as HTMLAnchorElement;
    let newUrl = `prospects/viewprospect?prospectId=${dataItem.prospectId}&listId=${this.selectedListId}&initiated=whiteboard`
    // Check if the anchor tag element exists
    if (anchorTag) {
      // Update the href attribute with the new URL
      anchorTag.href = newUrl;
    }
    //   let target = event.target as HTMLElement;
  }

  arraysAreIdentical(arr1: any, arr2: any) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  areWidthsEqual(columns1: any, columns2: any) {


    // Iterate over each column in the first array
    for (let i = 0; i < columns1.length; i++) {
      const columnName = columns1[i].columnName;

      // Check if the width of the column in the first array is defined
      if (columns1[i].width === undefined) {
        return false;
      }

      // Check if the width of the column in the first array is equal to the width in the second array
      if (columns1[i].width != 0 && columns1[i].width !== columns2[columnName]) {
        return false;
      }
    }
    return true;
  }
  compareVisibility(originalJson: any, updatedJson: any) {
    for (let i = 0; i < originalJson.length; i++) {
      const originalColumn = originalJson[i];
      const updatedColumn = updatedJson.find((column: any) => column.columnName === originalColumn.columnName);


      if (originalColumn.isVisible !== updatedColumn.isVisible) {
        return true;
      }
    }

    return false;
  }

  getListName(listid: any) {

    for (const item of this.prospectCreateList) {
      if (item.items) {
        for (const subItem of item.items) {
          if (subItem.value === listid) {
            return subItem.label;
          }
        }
      }
    }
    return '';

  }

  initializeMasterData(): void {
    const cacheFilters = this._dataCacheService.retrieveData('wba_filters');
    if (cacheFilters.length > 0) {
      this.filters = cacheFilters;
    }

    this.defaultList = this._dataCacheService.retrieveData('wba_defaultList');
    this.statesList = this._dataCacheService.retrieveData('wba_statesList');
    this.prospectIndustryList = this._dataCacheService.retrieveData('wba_prospectIndustryList');
    this.businessStatusList = this._dataCacheService.retrieveData('wba_businessStatusList');
    this.businessLine = this._dataCacheService.retrieveData('wba_businessLine');
    this.policyTypeList = this._dataCacheService.retrieveData('wba_policyTypeList');
    this.progress = this._dataCacheService.retrieveData('wba_progress');
    this.userList = this._dataCacheService.retrieveData('wba_userList');
    this.resultList = this._dataCacheService.retrieveData('wba_resultList');

    if (cacheFilters.length > 0) { this.mapFilterJSON(this.filters); this.mapWhiteboardCreateList(); }
    else this.getMasterData();
  }

  setDataInCache() {
    this._dataCacheService.compressAndCacheData('wba_filters', this.filtersList);
    this._dataCacheService.compressAndCacheData('wba_defaultList', this.defaultList);
    this._dataCacheService.compressAndCacheData('wba_statesList', this.statesList);
    this._dataCacheService.compressAndCacheData('wba_prospectIndustryList', this.prospectIndustryList);
    this._dataCacheService.compressAndCacheData('wba_businessStatusList', this.businessStatusList);
    this._dataCacheService.compressAndCacheData('wba_businessLine', this.businessLine);
    this._dataCacheService.compressAndCacheData('wba_policyTypeList', this.policyTypeList);
    this._dataCacheService.compressAndCacheData('wba_progress', this.progress);
    this._dataCacheService.compressAndCacheData('wba_userList', this.userList);
    this._dataCacheService.compressAndCacheData('wba_resultList', this.resultList);
    this._masterDataCacheService.compressAndCacheData('wba_policyTypeList', this.policyTypeList);
  }

  retrieveFromCache() {
    this.filters = this._dataCacheService.retrieveData('wba_filters');
    this.defaultList = this._dataCacheService.retrieveData('wba_defaultList');
    this.statesList = this._dataCacheService.retrieveData('wba_statesList');
    this.prospectIndustryList = this._dataCacheService.retrieveData('wba_prospectIndustryList');
    this.businessStatusList = this._dataCacheService.retrieveData('wba_businessStatusList');
    this.businessLine = this._dataCacheService.retrieveData('wba_businessLine');
    this.policyTypeList = this._dataCacheService.retrieveData('wba_policyTypeList');
    this.progress = this._dataCacheService.retrieveData('wba_progress');
    this.userList = this._dataCacheService.retrieveData('wba_userList');
    this.resultList = this._dataCacheService.retrieveData('wba_resultList');
    this.mapWhiteboardCreateList();
    this.mapFilterJSON(this.filters);
    // Repeat this pattern for the remaining lines

  }

  //get data
  getMasterData(): void {
    forkJoin([
      this._whiteBoardActivityService.getFilterJSON(),
      this._addWBAService.getBusinessLineList(),
      this._addWBAService.getBusinessStatusList(),
      this._addWBAService.getResultList(),
      this._addWBAService.getprogressList(),
      this._addWBAService.getPolicyTypeList(5),
      this._AddprospectService.getProspectStates(),
      this._addWBAService.getWhiteBoardActivityLookUp({ "lookUpType": 'AllUser', "rowCount": 500 }),
      this._AddprospectService.getProspectIndustry(),
      this._whiteBoardActivityService.getWbaList()
    ]).subscribe((responses: any[]) => {
      const [filterResponse, businessLineResponse, businessStatusResponse, resultListResponse, progressResponse, policyTypeResponse, statesResponse, whiteBoardActivityResponse, prospectIndustryResponse, wbaListResponse] = responses;

      this.defaultList = wbaListResponse.list;
      this.mapWhiteboardCreateList()

      this.filters = filterResponse.masterFilterOptionList;
      this.filtersList = JSON.parse(JSON.stringify(filterResponse.masterFilterOptionList));
      this.mapFilterJSON(this.filters);

      this.businessLine = businessLineResponse.businessLineList;
      this.businessStatusList = businessStatusResponse.businessStatusList;
      this.resultList = resultListResponse.epicStatusList;
      this.progress = progressResponse.progressList;
      this.policyTypeList = policyTypeResponse.businessLineList;
      this.statesList = statesResponse.statesList;
      this.userList = whiteBoardActivityResponse.userList;
      this.prospectIndustryList = prospectIndustryResponse.prospectIndustryList;
      this._masterDataCacheService.compressAndCacheData('wba_businessStatusList', businessStatusResponse);
      this._masterDataCacheService.compressAndCacheData('wba_businessLine', businessLineResponse);
      this.setDataInCache();
    });
  }

  mapWhiteboardCreateList() {
    this.prospectCreateList = [{
      label: 'Default List View',
      value: 'Default List View',
      items: [{ label: 'My Opportunities', value: '-3' },
      { label: 'All Whiteboards', value: '0' },
      { label: 'My Team Opportunities', value: '-2' },
      ],
    }];

    this.defaultList.forEach((item: any) => {
      let groupIndex = this.prospectCreateList.findIndex(
        (ob: any) => ob.label === item.listType
      );

      let listObj = { label: item.listName, value: item.listId.toString() };
      if (groupIndex === -1) {
        // group has not found
        let Obj = {
          label: item.listType,
          value: item.listType,
          items: [listObj],
        };
        this.prospectCreateList.push(Obj);
      } else {
        // group has found
        this.prospectCreateList[groupIndex].items.push(listObj);
      }
    });
    this.selectedDefaultListName = this.getListName(this.selectedListId);
  }


  private checkAuthorization(): void {
    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
    });
  }


  private initFilterOptions(): void {
    this.filterForAcordStatus = ['Requested', 'Not Needed', 'Incomplete Information', 'Incomplete Contact', 'Completed', 'In Process'];
    this.filterForLossRunStatus = ['Requested', 'Attached', 'Not Needed', 'Completed'];
    this.filterForApplicationStatus = ['Attached', 'Not Needed', 'Completed'];
    this.resultListForGrid = ['Bound', 'Not Quoted,Not Bound', 'Not Renewed', 'Quoted,Not Bound', 'Open'];
    this.clientStatus = ['Not a client', 'Client', 'Lost Client'];
    this.recallForStatusList = ['Open', 'Completed', 'Expired'];
    this.wbaForStatusList = ['Open', 'Won', 'Lost'];
    this.profitCenterData = ['Lamb', 'CREIS', 'TruePartners', 'TruePartners - Wheels'];

  }

  private setCurrentDate(): void {
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, 'YYYY-MM-dd');
  }

  private subscribeToProfileInfoChanges(): void {
    const selectionIdArray = JSON.parse(sessionStorage.getItem('mySelection')!);
    if (selectionIdArray) {
      this.mySelection = selectionIdArray;
    }

    const checkeddataWithoutHide = JSON.parse(sessionStorage.getItem('checkboxselectedWithoutHide')!);
    if (checkeddataWithoutHide) {
      this.checkboxCheckedData = checkeddataWithoutHide;
    }
  }

  private preventBackButton(): void {
    this._preventBack.preventBackButton();
  }

  private handlePageInitialization(): void {
    const wbaFilterStorageforCheckedRow = JSON.parse(sessionStorage.getItem('wbaFilterStorageforCheckedRow')!);
    if (wbaFilterStorageforCheckedRow) {
      this.checkedDataFilterStorage();
    } else {
      this.onloadApi = true;
      this.onCreateListChange({ value: this.selectedListId }, 'pageload');
    }
  }

  //this
  private checkFilterApplied(): void {
    if (this.sortingData?.filterJson) {
      this.selectedStateFilterValue = JSON.parse(this.sortingData.filterJson).filters.filter((x: any) => x.field == "State")[0]?.value;
      this.selectedLobFilterValue = JSON.parse(this.sortingData.filterJson).filters.filter((x: any) => x.field == "MasterBusinessLine")[0]?.value;
      this.selectedContactStateFilterValue = JSON.parse(this.sortingData.filterJson).filters.filter((x: any) => x.field == "Office1State")[0]?.value;
    }
  }

  userInformation(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');

    if (this.userDetails) {
      this.UserIdLogged = this.userDetails?.user?.userId;
      if (!this.sortingData) {
        this.selectedListId = this.userDetails.user.defaultWBAListId.toString();
      }
      this.selectedListId = sessionStorage.getItem('selectWBAID');
      if (this.selectedListId == undefined) this.selectedListId = this.userDetails.user.defaultWBAListId.toString();
      this.selectedDefaultList = this.selectedListId;
      this.pinnedListId = this.userDetails.user.defaultWBAListId;
      this.pinUnpinList = this.pinnedListId == this.selectedListId;

      const requiredRoles = ['System Manager', 'Admin', 'Sales Director'];
      this.isValidUser = this.userDetails.user.roles.some((role: any) => requiredRoles.includes(role.roleName));

      const wbaOpenRoles = ['Sales Director', 'Admin', 'Production Specialist'];
      this.isValidUserwbaOpen = this.userDetails.user.roles.some((role: any) => wbaOpenRoles.includes(role.roleName));

      const exportRoles = ['Sales Director', 'Admin'];
      this.isExportVisible = this.userDetails.user.roles.some((role: any) => exportRoles.includes(role.roleName));

      this.isBorRenuewshow = this.userDetails.user.roles.some((role: any) => role.roleName === 'Marketing Manager');
    }
  }

  checkedDataFilterStorage(): void {
    this.selectedDefaultList = this.selectedListId.toString();
    const wbaFilterStorageforCheckedRow = JSON.parse(sessionStorage.getItem('wbaFilterStorageforCheckedRow')!);
    if (wbaFilterStorageforCheckedRow) {
      const datasorting = JSON.parse(sessionStorage.getItem('SortingPageStorage')!);
      this.sort = datasorting?.sort;
      this.finalWBAList.data = wbaFilterStorageforCheckedRow;
      this.finalWBAList.total = wbaFilterStorageforCheckedRow.length;
      this.checkboxCheckedData = wbaFilterStorageforCheckedRow;
      this.unHideFlagForCheckBox = false;
      if (this.sort) {
        this.finalWBAList.data = orderBy(this.finalWBAList.data, this.sort);
        this.finalWBAList.total = wbaFilterStorageforCheckedRow.length;
      }
    } else
      this.checkboxCheckedData = [];


  }

  public mapFilterJSON(res: any): void {
    // Extract filter options for contact state
    let filterforContactState = res[7].filterOptions;

    // Initialize state array
    let stateArray: any[] = [];

    // Process each filter option
    this.filters.forEach((element: any) => {
      // Set operators for specific fields
      switch (element?.field) {
        case 'State':
          element.operators = ["eq"];
          stateArray.push(element.stateCode); // Push state code to array
          break;
        case 'ProspectInformation.NoOfEmployees':
        case 'ProspectInformation.Revenue':
        case 'ProspectInformation.PackagePremium':
        case 'ProspectInformation.WorkersCompensationPremium':
        case 'TargetPremium':
        case 'WBAOutComeDetail.BoundRevenue':
        case 'Prospect.TotalRevenue':
        case 'Prospect.DispositionCount':
          element.editorTemplate = this.templateForNumber; // Set editor template for number fields
          break;
        case 'ProspectInformation.EXPMOD':
          element.editorTemplate = this.templateForNumberEXP; // Set editor template for specific number field
          break;
        case 'WBA.WBAId':
        case 'WBA.ProspectId':
          element.editorTemplate = this.templateForNumberWithoutComma; // Set editor template for specific number field
          break;
      }

      // Set operators for specific fields
      switch (element?.field) {
        case 'MasterBusinessLine':
        case 'Office1State':
        case 'MasterPolicyType.line':
        case 'prospectstatus.Status':
        case 'WBARecall.Status':
        case 'WBAStatusId':
        case 'MasterBusinessStatus':
        case 'ProspectIndustry.IndustryName':
        case 'PI.IndustryName':
        case 'ProgressName':
        case 'EpicStatusName':
        case 'ProfitCenter':
        case 'WBA.OwnerUserName':
        case 'Prospect.CreatedBy':
        case 'WBA.CreatedBy':
        case 'WBA.MarketingManagerId':
        case 'Prospect.LastContactedBy':
        case 'Prospect.ModifiedBy':
        case 'wba.OriginalProducerName':
          element.operators = ["eq"];
          break;
        case 'industryclassandcodeinformation.Class.NTEE':
        case 'industryclassandcodeinformation.Class.PCS':
        case 'industryclassandcodeinformation.Class.SIC':
        case 'industryclassandcodeinformation.Class.NAICS':
          element.operators = ["contains", "eq", "neq"];
          break;
        case 'pmv_view.TagName':
        case 'Tag.Name':
          element.operators = ["contains", "eq", "neq", "doesnotcontain", "isnull"];
          break;
      }
    });

    // Set field values
    this.fieldValue = stateArray;

    // Process filter options for contact state
    let stateForContactArray: any[] = [];
    filterforContactState.forEach((element: any) => {
      stateForContactArray.push(element.stateCode);
    });
    this.fieldValueforContact = stateForContactArray;

    // Set editor templates for specific fields
    this.setEditorTemplates();
  }

  private setEditorTemplates(): void {
    let indexMapping: any = {
      'State': this.template1,
      'MasterBusinessLine': this.template2,
      'Office1State': this.template3,
      'MasterPolicyType.line': this.templateForPolicy,
      'prospectstatus.Status': this.templateForClientStatus,
      'WBARecall.Status': this.templateForRecallStatus,
      'WBAStatusId': this.templateForWbaStatus,
      'MasterBusinessStatus': this.templateForWbaBussinessStatus,
      'ProspectIndustry.IndustryName': this.templateForPrimaryIndustry,
      'PI.IndustryName': this.templateForSecondaryIndustry,
      'ProgressName': this.templateForProgress,
      'EpicStatusName': this.templateForResult,
      'ProfitCenter': this.templateprofitCenter,
      'WBA.OwnerUserName': this.templateForUserName,
      'Prospect.CreatedBy': this.templateForUserName,
      'WBA.CreatedBy': this.templateForUserName,
      'WBA.MarketingManagerId': this.templateForUserName,
      'Prospect.LastContactedBy': this.templateForUserName,
      'Prospect.ModifiedBy': this.templateForUserName,
      'wba.OriginalProducerName': this.templateForUserName,
    };

    Object.keys(indexMapping).forEach((field: string) => {
      let index = this.filters.findIndex((x: any) => x.field == field);
      if (index !== -1) {
        this.filters[index].editorTemplate = indexMapping[field];
      }
    });

    // Filter date fields
    this.dateFilters = this.filters.filter((obj: any) => obj.editor === 'date');
  }


}
